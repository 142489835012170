<template>
    <div class="page" v-loading.fullscreen.lock="fullscreenLoading">
        <div v-if="!fullscreenLoading">
            <el-card class="summary-card" id="pdf-details">
                <div style="display: flex;justify-content: center;">
                    <div class="summary-step">
                        <div class="booking-summary">{{ $t('bookingSummary.bookingSummary') }}</div>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.tourCode')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.trip?.tourCode}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.tourName')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.trip?.tourName}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.travelDate')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.trip?.duration}} Day(s)</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.tourDate')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.tourDate?booking.tourDate:"N/A"}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.tourLanguage')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.tourLanguage?booking.tourLanguage:"N/A"}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.consultant')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.consultant?booking.consultant:"N/A"}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.bookingRefNo')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.refNo?booking.refNo:"N/A"}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.passengers')}}</label></el-col>
                                <el-col :span="14">
                                    <div v-for="(item,index) in booking.passengers" :key="index">
                                        <label class="value">{{ item.firstName+" "+item.lastName }}</label>
                                        <label class="value" style="margin-left: 1rem;">{{ "Birthday:"+(item.birthday?item.birthday.split(" ")[0]:"") }}</label>
                                        <label class="value" style="margin-left: 1rem;">{{ "Passport:"+item.passportNo }}</label>
                                        <label class="value" style="margin-left: 1rem;">{{ "Gender:"+item.gender }}</label>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.numberOfPassengers')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{ booking.passengers.length }}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.numberOfRooms')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{(booking.singleRoom+booking.twinRoom+booking.doubleRoom+booking.tripleRoom)}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.pickupPointDetails')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.pickupPoint?booking.pickupPoint:"N/A"}}</label></el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.arrivalDetail')}}</label></el-col>
                                <el-col :span="14">
                                    <div v-for="(item,index) in booking.arrivalDetails" :key="index">
                                        <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>

                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.departureDetails')}}</label></el-col>
                                <el-col :span="14">
                                    <div v-for="(item,index) in booking.departureDetails" :key="index">
                                        <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>

                            <el-row :gutter="48" class="row" v-if="!booking.trip.inbound">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.preHotel')}}</label></el-col>
                                <el-col :span="14">
                                    <div v-for="(item,index) in booking.preTourAccommodations" :key="index">
                                        <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-divider v-if="!booking.trip.inbound"></el-divider>

                            <el-row :gutter="48" class="row"  v-if="!booking.trip.inbound">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.postHotel')}}</label></el-col>
                                <el-col :span="14">
                                    <div v-for="(item,index) in booking.postTourAccommodations" :key="index">
                                        <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-divider v-if="!booking.trip.inbound"></el-divider>

                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.bookingID')}}</label></el-col>
                                <el-col :span="14"><label class="value">N/A</label></el-col>
                            </el-row>
                            <el-divider></el-divider>

                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.tippingPrepaid')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.tippingPrepaid==true?"Yes":"No"}}</label></el-col>

                               
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.compulsoryPrepaid')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.compulsoryPrepaid==true?"Yes":"No"}}</label></el-col>
                            </el-row>

                            <el-divider></el-divider>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.specialRequest')}}</label></el-col>
                                <el-col :span="14"><label class="value">{{booking.specialRequest?booking.specialRequest:"N/A"}}</label></el-col>
                            </el-row>
                            <el-row :gutter="48" class="row">
                                <el-col :span="10"><label class="key">{{$t('bookingSummary.pickUpPoint')}}</label></el-col>
                                <el-col :span="14"><div class="value" v-html="booking.trip?.pickupPoint"></div></el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </div>
                        
                </div>
                    
            </el-card>
        </div>
        <div style="text-align: center;margin-top: 1rem;">
            <el-button type="primary" @click="downloadPDFClick">{{$t('bookingSummary.downloadPDF')}}</el-button>
        </div>
    </div>
  </template>
  
  <script>
  
import htmlPdf from '@/service/pdf';

  export default {
    props:{BookingId:null},
    name: 'booking-summary',
    data(){
        return {
            booking:new Object(),
            fullscreenLoading:true
        }
    },
    mounted(){
        this.getBookingDetails(this.BookingId);
    },
    methods:{
        getBookingDetails(id){
            this.fullscreenLoading=true;
            let that = this;
            this.$httpservice.get('api/Booking/Get',{id:id}).then(result => {
                this.fullscreenLoading=false;
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.booking = result.body.data;
                        
                    }else{
                        that.$message({
                                message: 'Get Booking info Failed',
                                type: 'warning'
                        });
                    }
                }
            });
        },
        downloadPDFClick(){
            htmlPdf("Booking Summary", document.querySelector('#pdf-details'));
        }
    }
   
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    .summary-card{
        margin-top: 2rem;
        width: 100%;
        background-color: #F5F7FB;
        .summary-step{
            width: 90%;
            .row{
                margin-top: 1.5rem;
            }
            .key{
                color:#444444;
                font-size: 1.12rem;
            }
            .value{
                color:#999999;
                font-size: 1.12rem;
            }
        }
        .booking-summary{
            background-color: #408F21;height: 3.125rem;color: white;display: flex;justify-content: center;align-items: center;
            
        }
    }
  </style>
  