<template>
    <div  style="display: flex;justify-content: center;padding: 2rem;">
        <div style="width: 90%;">
            <el-dropdown style="color: #60AB42;" @command="handleAreaSelectCommand">
              <span class="el-dropdown-link location-select">
                <i class="el-icon-location"></i>
                <label v-if="!selectedArea">{{$t('tourismTerms.selectArea')}}</label>
                <label v-if="selectedArea">{{$i18n.locale=='en'?selectedArea.englishName:selectedArea.chineseName}}</label>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in areas" :key="index" :command="index">{{item.englishName}}</el-dropdown-item>
                
                
              </el-dropdown-menu>
            </el-dropdown>
            <div v-if="selectedArea&&(selectedArea.englishName=='Asia'||selectedArea.englishName=='China')">
                <h3>
                中國和亞洲旅遊條款
                </h3>
                <div>
                    1.接待標識“趣旅游FUN TRIP HOLIDAY”。<br>
    2.僅提供中文導遊服務，一經報名視為旅客能以流利國/粵語交流的美加澳籍華僑參團，否則價格另議。<br>
    3.團費（報名費+自費專案+綜合服務費）均包含行程所列機場接送、酒店、中文導遊、汽車、門票、餐食等。<br>
    4.團費不含國際國內機票，中國簽證費，當地自費景點，旅遊與醫療保險，私人消費等。國際機票價格，簽證費用等請諮詢您的旅行社。<br>
    5.團費不含不可預見或無法避免的情況或事件發生時所產生的額外費用，如私人需求，航班晚點/取消，戰爭，暴亂，自然災害，惡劣的天氣環境等導致的損失。为避免旅客因各种原因无法参团所带来的损失，强烈建议客人提前购买必要的旅游保险（如医疗、疾病、意外或中途取消等），詳情請咨詢您的旅行社。<br>
    6.為保證團隊氣氛和諧，僅限美國/加拿大/澳大利亞/歐洲等國公民或美國綠卡/加拿大楓葉卡/澳洲永久居住證明/歐洲永久居住證明持有的中文流利之華人華僑參加；西人或其他國籍客人收費價格請諮詢客服。<br>
    7A超值購物團旅行團每天均有購物安排，購物純屬自願。絕無任何強迫購物行為，但團員不得以任何理由拒絕逛店參觀。旅途中購買的商品品質/價格、售後服務等請與商店直接聯繫。<br>
    7B.高品質純玩無購物團，全程不安排旅遊定點購物商店。行程中個別景區或景點附帶商店有紀念商品或特產出售為文化參觀部分，不屬於指定購物店，無任何購物壓力。<br>
    8A.超值購物旅行團遊客必須隨團旅行，全程不得中途脫團，如脫團每天需補回旅行社USD$150/人/天離團費。<br>
    8B.高品質純玩無購物團遊客必須隨團旅行，全程不得中途脫團，如脫團則已繳納所有費用無任何退還。<br>
    9.綜合服務費和自費專案收費為團費的重要組成部分，請在報名時或在旅遊當地足額支付，自費專案為含門票，車費，導遊費，操作費在內的綜合包價項目。<br>
    10.A成人報名費价格以两人一房基准计算，如遇其中一人取消，没有或中断参团，另一人须补付单人房差价。為客人自身安全考慮，恕本社無法代為安排拼房。<br>
    10.B兒童收費標準：2周歲以上小童需要支付報名費，綜合服務費和自費專案，2周歲以下不占床小童以上三項費用均免費。不占床兒童不含早餐，早餐費用酒店內自理。<br>
    11.單人房差、酒店續住價格、額外接送收費價格:請參考各線路網站標注以及廣告單頁。<br>
    12.接機時間（請報名時提供抵達航班資訊資料）：中國線路抵達當日全天候指定機場接機，間隔二小時左右發車一次分批送回酒店。亞洲線路各國接機時間規定各不相同，請參考網站和行程表內具體說明。<br>
    13.送機時間：中國線路結束當日指定時間分批機場送機。亞洲線路各國送機時間規定各不相同，請參考行程內具體說明。<br>
    14.特價團報名之後無論任何原因均不得退團，退團損失費用請諮詢客服。<br>
    15.廣告行程內容僅供參考，旅行社有權在不影響遊覽節目的前提下對行程做次序調整，本公司擁有最終解釋權。<br>
    16.如因國家政策法規等因素或地震，颱風等不可抗力因素導致我公司團隊無法成行，我社將提前通知並將免費提供改期或取消訂位服務，但除團款外一切損失請自行承擔。<br>
    17.為維護團體所有客人最高利益，若任何旅客的健康或行為有妨礙團體正常活動或利益之情況，本公司領隊有權取消其繼續參團之資格，其未完之行程，不得要求退款、變更或轉讓；無論任何原因自行離團，自旅客離團之時起，其一切活動概與本公司無關。<br>
    18.七十五周歲以上長者參團必須有六十周歲以下親友隨行並自行購買足額旅遊意外保險。所有客人一經報名則默認知道自身健康狀況適合參團，如參團過程中發生任何意外恕本公司無法負責，由此產生的各種費用和責任由客人自行承擔。<br>
    19. 旅客须自行承擔隨身財物的保管責任，如在參團期间遭遇失竊、遺失而引致金錢及物品的損失，本公司、隨團司機及導遊均不負有任何責任。<br>
    20. 遊客一經報名，即視為已充分瞭解並確定自己的身體健康狀況適合參加本次旅遊團。 因個人既有病史和身體殘障在旅遊行程中引起的疾病進一步發作和傷亡，旅行社不承擔任何責任。① 報名時旅遊者應確保身體健康，保證自身條件能够完成旅遊活動，身體健康狀況不佳者，請諮詢醫生是否可以參加本次旅遊活動，根據自身情况備好常用藥和急救藥品，因自身疾病而引起的後果，遊客自行承擔責任； 如因此影響到正常走團秩序，旅行社有權終止其繼續參團資格且所繳費用恕無法退回，強烈建議遊客購買必要之旅遊保險；② 遊客出現急症請主動通知工作人員，旅行社將協助遊客就近送往當地醫療機構檢查治療，費用由遊客自理；③ 有聽力、視力障礙、個人有精神疾病或無行為控制能力的遊客不建議參團；④ 有心、肺、腦和血液系統疾病患者以及其它不適合長途疲勞的人群以及75歲以上老人及孕婦，應在諮詢醫生意見後自行决定是否參加旅行團。 為了你的安全請勿隱瞞病情，感謝您的配合！<br>
    21.其他未盡事宜請諮詢組團社，凡報名之客人均視為已瞭解/接收我公司之旅遊條款。<br>
    
    <h3>訂位及取消辦法</h3><br>
    1、報名：請攜帶有效旅遊證件至代理旅行社辦理報名手續，需提供旅遊證件影本，填寫報名表並簽字。報名時請指定參加“趣旅遊FUN TRIP HOLIDAY”旅遊團，品質才有鑽石般的保證。<br>
    2、訂金：報名訂位時須至少繳交報名費，自費項目及綜合服務費也是團費的重要組成部分，報名費+指定自費+綜合服務費請最晚於落地旅遊目的地當日或之前全額支付。從代理旅行社收到團款之時起，旅遊合同即告成立。<br>
    3、更改：對於交付團款後則不可有任何更改。如一定需要更改，則按取消辦法處理。<br>
    4、取消訂位及退款辦法：取消訂位需讓代理旅行社書面方式通知本公司辦理，恕不接受口頭取消。無論任何非本公司原因而導致的取消訂位均有可能會產生罰款(亞洲線路以及包含遊船、機票等線路抵達日期前15日內取消團費+自費專案+綜合服務費全損。其他各線路損失費各有不同，請諮詢客服，我公司保留最終解釋權）。旅客未在指定地點指定時間參加旅行團均被視為自动放棄整個行程。團隊出發後，任何提前购买的門票費用，項目費用，餐費等恕不退還。此外不論在出發日期前多久，如果代理旅行社國際機票已開，則因此產生的全部退票費用，包括各種手續費等也需客人按航空公司規定自行負擔，具體請諮詢您的旅行社。<br>
                </div>

            </div>
           
        </div>
       


    </div>
  </template>
  
  <script>
  export default {
    name: 'tourism-terms',
    data(){
        return {
            selectedArea:null,
            areas:[]
        }
    },
    mounted(){
        this.getAreas();
    },
    methods:{
        getAreas(){
            let that = this;
            this.$httpservice.get('api/common/GetArea',).then(result => {
            if(result.status==200){
                if(result.body.code=='200'){
                    that.areas = result.body.data;
                    if(that.areas.length>0){
                        that.selectedArea = that.areas[0];
                    }
                }else{
                this.$message({
                        message: 'Get Area infos failed',
                        type: 'warning'
                });
                }
            }
            });
        },
        handleAreaSelectCommand(command){
            console.log(command);
            this.selectedArea = this.areas[command];
        },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  </style>
  