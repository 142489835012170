<template>
    <div>
        <div class="content">
            <el-card class="card">
                <div>
                    <div class="title">
                        <label>{{$t('personalCenter.changePassword')}}</label>
                    </div>
                    <div class="new-pwd">
                        <label>{{$t('personalCenter.newPassword')}}</label>
                    </div>
                    <div class="my-input">
                        <el-input v-model="newPwd"  :placeholder="$t('personalCenter.enterYourPassword')" show-password></el-input>
                    </div>
                    <div class="my-input">
                        <el-input v-model="confirmNewPwd" :placeholder="$t('personalCenter.confirm')" show-password></el-input>
                    </div>
                    <div class="submit">
                        <el-button type="primary" @click="submit">{{ $t('personalCenter.submit') }}</el-button>
                    </div>
                </div>
            </el-card>
        </div>
        <FooterPage></FooterPage> 
    </div>
    
</template>
  
<script>
 import FooterPage from '../../../components/footer.vue';
export default {
    components:{FooterPage},
    name: 'personal-center',
    data(){
        return {
            newPwd:"",
            confirmNewPwd:""
        }
    },
    methods:{
        submit(){
            let that = this;
            if(this.newPwd==this.confirmNewPwd&&this.confirmNewPwd&&this.newPwd){
                if(this.newPwd.length>=6){
                    let obj=new Object();
                    obj.password=this.newPwd;
                    obj.id=this.$account.id;
                    this.$httpservice.put('api/Account/Update',obj).then(result => {
                            if(result.status==200){
                                if(result.body.code=='200'){
                                    that.$message({
                                        message: 'Update Password Successfully',
                                        type: 'success'
                                    });
                                }else{
                                    that.$message({
                                        message: 'Operation Failed',
                                        type: 'warning'
                                    });
                                }
                            }
                    });
                }else{
                    that.$message({
                    message: 'The minimun length of password is 6',
                    type: 'warning'
                });
                }
            }else{
                that.$message({
                    message: 'Please ensure that the passwords are same',
                    type: 'warning'
                });
            }
        }
    }
   
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.submit{
    text-align: right;
}
.my-input{
    margin-bottom: 1rem;
}
.title{
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #167D5B;
    font-size: 2rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #167D5B;
    line-height: 1.3rem;
}
.new-pwd{
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;
    line-height: 1.3rem;
}
.content{
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 3rem;
}
.card{
    background-color: #F5F7FB;
}

</style>
  