
import Vue from "vue"
import VueResource from "vue-resource"
Vue.use(VueResource)


export default {
    //host:"http://localhost:5001/", //本地测试
    //host:"http://106.14.2.73/",//远程测试
    host:"https://www.funtripholidayus.net/",//远程测试
    //host:"/", //正式发布
    //host:"http://icloud.lhcoffeetime.com:8080/",//另一个版本的远程测试

    //天地图逆编码host
    tdhost:"http://api.tianditu.gov.cn/",
    minimaphost:"https://api.map.baidu.com/",
    get(url,param,headers){
        return Vue.http.get(this.host+url,
            {
                params:{...param},
                headers: {
                    Authorization: "Bearer " + (Vue.$cookies.get("token")!=null?Vue.$cookies.get("token"):""),
                        ...headers
                }
            }
        );
    },

   

    post(url,body,headers){
        return Vue.http.post(this.host+url,body,
            {
                headers: {
                    Authorization: "Bearer " + (Vue.$cookies.get("token")!=null?Vue.$cookies.get("token"):""),
                    ...headers
                }
            }
        );
    },


    put(url,body,headers){
        return Vue.http.put(this.host+url,body,
            {
                headers: {
                    Authorization: "Bearer " + (Vue.$cookies.get("token")!=null?Vue.$cookies.get("token"):""),
                    ...headers
                }
            }
        );
    },

    delete(url,param,headers){
        return Vue.http.delete(this.host+url,
            {
                params:{...param},
                headers: {
                    Authorization: "Bearer " + (Vue.$cookies.get("token")!=null?Vue.$cookies.get("token"):""),
                        ...headers
                }

            }
        );
    }
}

//export  service;
