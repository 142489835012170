export default {
    login: {
        title: 'USER LOGIN',
        accountPlaceholder:'ACCOUNT',
        passwordPlaceholder:'PASSWORD',
        btnText: 'LOGIN'
    },
    home:{
        news:"Latest News",
        outbound:"Outbound Tour",
        inbound:"Inbound Tour",
        mybooking:"My Bookings",
        commission:"Commission",
        tourismtermsPage:"Tourism Terms",
        contractus:"Contract Us",
        logout:"Logout",
        login:"Login",
        select:"Select"
    },
    commission:{
        refresh:"Refresh",
        tourNo:"Tour No",
        tourTitle:"Tour Title",
        adultPrice:"Adult Gross",
        adultCommission:"Adult Commission",
        childWithBedPrice:"Child With Bed Price",
        childWithBedCommission:"Child With Bed Commission",
        childWithoutBedPrice:"Child No Bed Price",
        childWithoutBedCommission:"Child No Bed Commission",
        comprehensiveServiceFee:"Compulsory Service Fee",
        compulsoryProgramFee:"Compulsory Program Fee",
        simpleSup:"Single Supp",
        select:"Select",
        outboundTour:"OutBound Tours",
        inboundTour:"Inbound Tours",
    },
    contractUs:{
        contractUs:"Contract US",
        generalEnquiries:"General Enquiries",
        generalEnquiriesAndBooking:"General Enquiries & Booking",
        businessHour:"Business Hour",
        usEastCoastTime:"US East Coast Time",
        orPleaseContractOurPreferredAgents:"Or please contract our preferred agents",
        scanQRCode:"Scan QR code to add customer service WeChat",
        followUS:"Follow US"
    },
    inboundTour:{
        search:"Search",
        englishNameOrChineseName:"English Name/Chinese Name",
        tourCode:"Tour Code",
        tourName:"Tour Name",
        countryAndTour:"Country & Tour",
        duration:"Duration",
        priceForm:"Price From",
        startCity:"Start city",
        endCity:"End city",
        view:"View",
        book:"Book",
        ViewDetail:"View Detail",
        back:"Back",
        day:"day",
        daysOfSchedule:"Period of Schedule",
        priceList:"Price List",
        itineraryFeatures:"Itinerary Features",
        departureDate:"Departure date",
        remark:"Remark",
        pickUpPoint:"Pick Up Point",
        downloadPoster:"Download Poster",
        noticeToAttendees:"Notice to Attendees",

    },
    outboundTour:{
        search:"Search",
        englishNameOrChineseName:"English Name/Chinese Name",
        tourCode:"Tour Code",
        tourName:"Tour Name",
        countryAndTour:"Country & Tour",
        duration:"Duration",
        priceForm:"Price From",
        startCity:"Start city",
        endCity:"End city",
        view:"View",
        book:"Book",
        ViewDetail:"View Detail",
        back:"Back",
        day:"day",
        daysOfSchedule:"Period of Schedule",
        priceList:"Price List",
        itineraryFeatures:"Itinerary Features",
        departureDate:"Departure date",
        remark:"Remark",
        pickUpPoint:"Pick Up Point",
        downloadPoster:"Download Poster",
        noticeToAttendees:"Notice to Attendees",
        dateAndPrice:"Date and price",
        scheduling:"scheduling"
    },
    news:{
        latestNews:"Latest News",
        detail:"Details",
        topNews:"Top News",
    },
    newsInfo:{
        back:"Back"
    },
    mybooking:{
        bookingID:"Booking ID",
        bookingRefNo:"Booking Ref No",
        tourName:"Tour Name",
        tourDate:"Tour Date",
        search:"Search",
        export:"Export",
        pax:"Pax",
        paymentStatus:"Payment Status",
        passengerName:"Passenger Name",
        yourRef:"Your Ref",
        consultant:"Consultant",
        bookingDate:"Booking Date",
        status:"Status",
        processing:"Processing",
        confirmed:"Confirmed",
        cancelled:"Cancelled",
        view:"View",
        voucher:"Voucher",
        invoice:"Invoice",
        back:"Back"
    },
    voucher:{
        bookingNo:"Booking No",
        invoiceNo:"Invoice No",
        tourCode:"Tour Code",
        agentName:"Agent Name",
        tourName:"Tour Name",
        tourDate:"Tour Date",
        consultant:"Consultant",
        refNo:"Ref No.",
        travellers:"Travellers",
        name:"Name",
        gender:"Gender",
        passengerInfo:"Passenger Info",
        adult:"Adult",
        childWithBed:"Child with Bed",
        childWithoutBed:"Child without Bed",
        arrivalDetails:"Arrival Details",
        departureDetails:"Departure Details",
        noOfRooms:"No of Rooms",
        singleRoom:"Single Room",
        twinRoom:"Twin Room",
        twinRoomExtraBed:"Twin Room Extra Bed",
        tippingPrepaid:"Tipping Prepaid",
        compulsoryProgramPrepaid:"Compulsory Program Prepaid",
        yes:"Yes",
        no:"No",
        extraItemDescription:"Extra Item Description",
        pickupPoint:"Pickup Point",
        remark:"Remark",
        tourGuide:"Tour Guide",
        scheduling:"Scheduling",
        downloadPDF:"Download PDF"
    },
    personalCenter:{
        changePassword:"Change Password",
        newPassword:"New Password",
        enterYourPassword:"Enter your password",
        confirm:"Confirm",
        submit:"Submit"
    },
    tourismTerms:{
        selectArea:"Select Area"
    },
    bookingInvoice:{
        invoiceDate:"Invoice Date",
        agentName:"Agent Name",
        consultant:"Consultant",
        invoiceNo:"Invoice No",
        refNo:"Ref No",
        bookingID:"Booking ID",
        name:"Name",
        description:"Description",
        unitPrice:"Unit Price",
        qty:"Qty",
        subtotal:"Sub Total",
        cancelled:"Cancelled",
        total:"Total",
        termAndCondition:"Term and Condition",
        downloadPDF:"Download PDF"
    },
    bookingSummary:{
        bookingSummary:"Booking Summary",
        tourCode:"Tour Code",
        tourName:"Tour Name",
        travelDate:"Travel Date",
        tourDate:"Tour Date",
        tourLanguage:"Tour Language",
        consultant:"Consultant",
        bookingRefNo:"Booking Ref No",
        passengers:"Passenger/s",
        numberOfPassengers:"Number Of Passenger/s",
        numberOfRooms:"Number Of Room/s",
        pickupPointDetails:"Pickup Point Details",
        arrivalDetail:"Arrival Detail",
        departureDetails:"Departure Details",
        preHotel:"Pre Hotel",
        postHotel:"Post Hotel",
        bookingID:"Booking ID",
        tippingPrepaid:"Tipping Prepaid",
        compulsoryPrepaid:"Compulsory Prepaid",
        specialRequest:"Special Request",
        pickUpPoint:"Pick up Point",
        downloadPDF:"Download PDF"
    },
    bookingTour:{
        selectTour:"Select Tour",
        dateAndLanguage:"Date & Language",
        selectATourDate:"Select a tour date",
        selectATourLanguage:"Select a tour Language",
        consultant:"Consultant",
        yourBookingReferNo:"Your Booking refer No",
        next:"Next",
        passenger:"Passenger",
        passengerDetails:"Passenger Details",
        lastName:"Last Name",
        firstName:"First Name",
        birthday:"Birthday",
        selectDate:"Select Date",
        gender:"Gender",
        nationality:"Nationality",
        pleaseSelect:"Please Select",
        PR:"PR",
        passportNo:"Passport No",
        pleaseInput:"Please input",
        speaking:"Speaking",
        passengerType:"Passenger Type",
        needbed:"Need bed",
        back:"Back",
        roomConfigure:"Room Configure",
        singleRoom:"Single Room",
        twinRoom:"Twin Room",
        twinRoomExtraBed:"Twin Room Extra Bed",
        numberOfRooms:"Number of rooms",
        serviceRequest:"Service Request",
        transferServices:"Transfer Services",
        arrivalDetails:"Arrival Details",
        flightNo:"Flight No",
        noneSelected:"None Selected",
        departureDetails:"Departure Details",
        prePostTourAccommodation:"Pre/Post Tour Accommodation",
        preTourAccommodation:"Pre Tour Accommodation",
        postTourAccommodation:"Post Tour Accommodation",
        specialRequest:"Special Request",
        pleaseTypeYourSpecialRequest:"Please type your Special Request",
        isCompulsoryProgramPrepaid:"ls Compulsory program prepaid",
        isTippingprepaid:"ls Tipping prepaid",
        roomType:"-- Room Type --",
        night:"-- Night/s --",
        oneNight:"One Night",
        twoNight:"Two Nights",
        threeNight:"Three Nights",
        fourNight:"Four Nights",
        fiveNight:"Five Nights",
        single:"Single",
        double:"Double",
        twin:"Twin",
        triple:"Triple",
        submit:"Submit"
    }

}