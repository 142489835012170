<template>
    <div>
        <div class="carousel">
                <el-carousel>
                    <el-carousel-item v-for="(item,index) in rotationCharts" :key="index">
                        <a>
                            <img :src="item.picture.path">
                        </a>
                    </el-carousel-item>
                </el-carousel>
            </div>
       <div class="contract-us">
            <label>{{ $t('contractUs.contractUs') }}</label>
       </div>
       <div class="content">
            <el-card class="card">
                
                <div>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >{{$t('contractUs.generalEnquiries')}}:</label></el-col>
                        <el-col :span="8"><label >+1(888)658-8558</label></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >{{$t('contractUs.generalEnquiriesAndBooking')}}:</label></el-col>
                        <el-col :span="8"><label >booking@funtripholidayus.com</label></el-col>
                    </el-row>
                    
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <el-row>
                        <el-col :offset="2" :span="8">
                            <h4>{{$t('contractUs.businessHour')}}:</h4>
                        </el-col>                      
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >{{$t('contractUs.usEastCoastTime')}}: Mon - Fri 10:00 - 20:00</label></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >{{$t('contractUs.usEastCoastTime')}}: Sat 10:00 - 17:00</label></el-col>
                    </el-row>
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><h4>{{$t('contractUs.orPleaseContractOurPreferredAgents')}}:</h4></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >{{$t('contractUs.scanQRCode')}}</label></el-col>
                        
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8">
                            <img style="width: 15rem;height: 20rem;" src="../../../assets/wechat-contact-us.png">
                        </el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >{{$t('contractUs.followUS')}}：</label></el-col>                       
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8">
                            <img style="width: 15rem;height: 15rem;" src="../../../assets/wechat-follow-us.png">
                        </el-col>
                    </el-row>
                    
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <!--
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >Address:</label></el-col>
                        <el-col :span="8"><label >Suite 110, Level 1, 4-10 Goulburn Street Sydney NSW 2000</label></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >Trading Hours:</label></el-col>
                        <el-col :span="8"><label >Mon - Fri 10:00 - 18:00<br>Sat & Sun 10:00 - 15:00</label></el-col>
                    </el-row>
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >Or please contract our preferred agents:</label></el-col>
                       
                    </el-row>
                    -->
                </div>
            </el-card>

            <el-card class="card-mobile">
                
                <div>
                    <el-row class="row">
                        <el-col :span="12"><label >General Enquiries:</label></el-col>
                        <el-col :span="12"><label >+1(888)658-8558</label></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :span="12"><label >General Enquiries & Booking:</label></el-col>
                        <el-col :span="12"><label >booking@funtripholidayus.com</label></el-col>
                    </el-row>
                    
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <el-row>
                        <el-col :span="8">
                            <h4>Business Hour:</h4>
                        </el-col>                      
                    </el-row>
                    <el-row class="row">
                        <el-col :span="24"><label >US East Coast Time: Mon - Fri 10:00 - 20:00</label></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :span="24"><label >US East Coast Time: Sat 10:00 - 17:00</label></el-col>
                    </el-row>
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <el-row class="row">
                        <el-col :span="24"><h4>Or please contract our preferred agents:</h4></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :span="24"><label >Scan QR code to add customer service WeChat</label></el-col>
                        
                    </el-row>
                    <el-row class="row">
                        <el-col :span="24" style="text-align: center;">
                            <img style="width: 15rem;height: 20rem;" src="../../../assets/wechat-contact-us.png">
                        </el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :span="24"><label >Follow US：</label></el-col>                       
                    </el-row>
                    <el-row class="row">
                        <el-col :span="24" style="text-align: center;">
                            <img style="width: 15rem;height: 15rem;" src="../../../assets/wechat-follow-us.png">
                        </el-col>
                    </el-row>
                    
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <!--
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >Address:</label></el-col>
                        <el-col :span="8"><label >Suite 110, Level 1, 4-10 Goulburn Street Sydney NSW 2000</label></el-col>
                    </el-row>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >Trading Hours:</label></el-col>
                        <el-col :span="8"><label >Mon - Fri 10:00 - 18:00<br>Sat & Sun 10:00 - 15:00</label></el-col>
                    </el-row>
                    <div class="split-line-container">
                        <div></div>
                    </div>
                    <el-row class="row">
                        <el-col :offset="2" :span="8"><label >Or please contract our preferred agents:</label></el-col>
                       
                    </el-row>
                    -->
                </div>
            </el-card>
       </div>
      
        <FooterPage></FooterPage> 
    </div>
    
</template>
  
<script>

    import FooterPage from '../../../components/footer.vue';

    export default {
        components:{FooterPage},
        name: 'contract-us',
        data(){
            return {
                rotationCharts:[]
            }
        },
        mounted(){
            this.getRotationCharts();
        },
        methods:{
            getRotationCharts(){
                let that = this;
                this.$httpservice.get('api/RotationChart/list',{activate:true,area:this.area?.value}).then(result => {
                    if(result.status==200){
                        if(result.body.code=='200'){
                            that.rotationCharts = result.body.data;
                        }else{
                            this.$message({
                                    message: 'Get Rotation Charts Failed',
                                    type: 'warning'
                            });
                        }
                    }
                });
            }
        }

    }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){
    .content{
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .carousel{
        height: 58vh;
        img{
            width: 100%;
            height: 100%;
        }
    }

    
    .card{
        background-color: #F5F7FB;
        label{
            color: #444444;
            word-wrap: break-word;
        }
        .row{
            margin-top: 1.5rem;
        }
    }

    .card-mobile{
        display: none;
    }

    .split-line-container{
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        div{
            width: 90%;
            height: 1px;
            background-color: #999999;
            opacity: 0.37;
            //border: 1px solid #999999;
        }
    }

    .contract-us{
        color: #167D5B;line-height: 1.3rem;font-weight: 400;font-size: 1.5rem;font-family: Source Han Sans SC;margin-top: 3rem;margin-left: 4rem;margin-bottom: 1rem;
    }
}

@media screen and (max-width:1000px){
    .content{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .carousel{
        img{
            width: 100%;
            height: 10.375rem;
        }
    }

    .card{
        display: none;
    }
    .card-mobile{
        background-color: #F5F7FB;
        label{
            color: #444444;
            word-wrap: break-word;
        }
        .row{
            margin-top: 1.5rem;
        }
    }

    .split-line-container{
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        div{
            width: 100%;
            height: 1px;
            background-color: #999999;
            opacity: 0.37;
            //border: 1px solid #999999;
        }
    }

    .contract-us{
        color: #167D5B;line-height: 1.3rem;font-weight: 400;font-size: 1.5rem;font-family: Source Han Sans SC;margin-top: 3rem;margin-left: 2rem;margin-bottom: 1rem;
    }
}

@media screen and (max-aspect-ratio:16/16) {
    .content{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .carousel{
        img{
            width: 100%;
            height: 10.375rem;
        }
    }

    .card{
        display: none;
    }

    .card-mobile{
        background-color: #F5F7FB;
        label{
            color: #444444;
            word-wrap: break-word;
        }
        .row{
            margin-top: 1.5rem;
        }
    }

    .split-line-container{
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        div{
            width: 100%;
            height: 1px;
            background-color: #999999;
            opacity: 0.37;
            //border: 1px solid #999999;
        }
    }

    .contract-us{
        color: #167D5B;line-height: 1.3rem;font-weight: 400;font-size: 1.5rem;font-family: Source Han Sans SC;margin-top: 3rem;margin-left: 2rem;margin-bottom: 1rem;
    }
}







.location-group{
    padding: 1.5rem 8.6rem 1.5rem 8.6rem;
    .location-container{
        display: flex;
        flex-wrap:wrap;
        .location-item{
            margin-right: 1rem;
            margin-top: 1rem;
        }
    }
}

.search{
    display: flex;
    justify-content: center;
    .item{
        margin-right: 1rem;
    }
    div{
        width: 40%;
        display: flex;
        justify-content: center;
    }
}

.table-container{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.table-content{
   width: 90%;
   text-align: center;
   margin-top: 1rem;
}

</style>
  