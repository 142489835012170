<template>
    <div>
        <div v-if="!showDetail">
            <div class="carousel">
                <el-carousel>
                    <el-carousel-item v-for="(item,index) in rotationCharts" :key="index">
                        <a>
                            <img :src="item.picture.path">
                        </a>
                    </el-carousel-item>
                </el-carousel>
            </div>
        
            <div style="text-align: center;color: #417D5A;font-size: 1.375rem;font-weight: 400;margin-top: 2rem;margin-bottom: 2rem;">{{$t('news.latestNews')}}</div>
            <div style="padding-left: 10vw;padding-right: 10vw;">
                <el-row :gutter="48" class="news-content">
                    <el-col :span="16">
                        <el-card v-for="(item,index) in news" :key="index" class="card-Container">
                            <div class="card-content">
                                <div class="image">
                                    <img :src="item.picture.path">
                                </div>
                                
                                <div class="content">
                                    <el-row>
                                        <el-col :span="18"><div class="title">{{item.title}}</div></el-col>
                                        <el-col :span="6"><div class="time">{{item.createdOn}}</div></el-col>
                                    </el-row>
                                    <div class="desrciption">
                                        <span>{{item.subTitle}}</span>
                                    </div>
                                    <div class="action">
                                        <el-button type="primary" size="mini" @click="gotoNewsInfo(item.id)">{{$t('news.detail')}}</el-button>
                                        <!--<el-button type="primary" size="mini">彩页下载</el-button>-->
                                    </div>
                                </div>
                            </div>
                            
                        </el-card>
                        <el-pagination
                            class="table-content"
                            background
                            layout="prev, pager, next"
                            :total="newsTotalCount"
                            @size-change="handleNewsPageSizeChange"
                            @current-change="handleNewsCurrentPageChange"
                            :current-page="currentNewsPage"
                            :page-size="newsPageSize"
                            >
                        </el-pagination>
                    </el-col>
                    <el-col :span="8">
                        <el-card class="top-news">
                            <div >
                                <div class="title">{{$t('news.topNews')}}</div>
                                <div class="top-news-item" v-for="(item,index) in topNews" :key="index">
                                    <div  class="btn"><el-button style="width: 1.98rem;" size="small" type="primary" circle>{{index+1}}</el-button></div>
                                    
                                    <a @click="gotoNewsInfo(item.id)" class="content">{{item.title}}</a>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>


                <el-row :gutter="48" class="news-content-mobile">
                    <el-col :span="24">
                        <el-card class="top-news">
                            <div >
                                <div class="title">{{$t('news.topNews')}}</div>
                                <div class="top-news-item" v-for="(item,index) in topNews" :key="index">
                                    <div  class="btn"><el-button style="width: 1.98rem;" size="small" type="primary" circle>{{index+1}}</el-button></div>
                                    
                                    <a @click="gotoNewsInfo(item.id)" class="content">{{item.title}}</a>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="24">
                        <el-card v-for="(item,index) in news" :key="index" class="card-Container">
                            <div class="card-content"  @click="gotoNewsInfo(item.id)">
                                <div class="image">
                                    <img :src="item.picture.path">
                                </div>
                                
                                <div class="content">
                                    <el-row>
                                        <el-col :span="24"><div class="title">{{item.title}}</div></el-col>
                                    </el-row>
                                    
                                    <div class="desrciption">
                                        <span>{{item.subTitle}}</span>
                                    </div>
                                    <el-row>
                                        <el-col :span="24"><div class="time">{{item.createdOn}}</div></el-col>
                                    </el-row>
                                    
                                </div>
                            </div>
                            
                        </el-card>
                        <el-pagination
                            class="table-content"
                            background
                            layout="prev, pager, next"
                            :total="newsTotalCount"
                            @size-change="handleNewsPageSizeChange"
                            @current-change="handleNewsCurrentPageChange"
                            :current-page="currentNewsPage"
                            :page-size="newsPageSize"
                            >
                        </el-pagination>
                    </el-col>
                    
                </el-row>


            </div>
        </div>
        
        <div v-if="showDetail">
            <NewsInfo :NewsID="currentNewsId" @back="handleNewsInfoBack"></NewsInfo>
        </div>
        
        <div>
            <FooterPage></FooterPage> 
        </div>
       
    </div>
    
</template>
  
<script>

    import FooterPage from '../../../components/footer.vue';
    import NewsInfo from './news-info.vue';
    export default {
        components:{FooterPage,NewsInfo},
        props:{
            Area:null

        },
        watch:{
            /*
            Area:{
                handler(newValue,oldValue){
                    console.log(111111111,oldValue);
                    this.area = newValue;
                    this.initData();
                }
                
			}
            */

            Area:function(newValue,oldValue){
                console.log(111111111,oldValue);
                this.area = newValue;
                this.initData();
                
			}
        },
        name: 'latest-news',
        
        data(){
            return {
                showDetail:false,
                selectedLocation:"Day Tour",
                tourTableData:[],
                news:[],
                topNews:[],
                rotationCharts:[],    
                newsPageSize:5,
                newsTotalCount:0,
                currentNewsPage:1,
                currentNewsId:0,
                area:null
            }
        },
        mounted(){
            this.area = this.Area;
            this.initData();
        },
        methods:{
            handleNewsInfoBack(e){
                console.log(e);
                this.showDetail=false;
            },
            initData(){
                this.getNews(1);
                this.getTopNews();
                this.getRotationCharts();
                
            },
            gotoNewsInfo(newsId){
                this.showDetail = true;
                this.currentNewsId = newsId;
                console.log(newsId);
            },
            handleNewsCurrentPageChange(e){
                this.currentNewsPage=e;
                this.getNews(2);
            },
            handleNewsPageSizeChange(e){
                this.newsPageSize=e;
                this.getNews(1);
                
            },
            
            getNews(pageIndex){
                let that = this;
                let districtData = this.$district;
                if(this.$account){
                    districtData = this.$account.area;
                }
                this.$httpservice.get('api/News/list',{pageIndex:pageIndex,pageSize:this.newsPageSize,area:this.area?.value,district:districtData}).then(result => {
                    if(result.status==200){
                        if(result.body.code=='200'){
                            that.news = result.body.data;
                            that.newsTotalCount = result.body.totalCount;
                        }else{
                            that.$message({
                                    message: 'Operation Failed',
                                    type: 'warning'
                            });
                        }
                    }
                });
            },
            getTopNews(){
                let that = this;
                let districtData = this.$district;
                if(this.$account){
                    districtData = this.$account.area;
                }
                this.$httpservice.get('api/News/list',{pageIndex:1,pageSize:10,area:this.area?.value,district:districtData}).then(result => {
                    if(result.status==200){
                        if(result.body.code=='200'){
                            that.topNews = result.body.data;
                        }else{
                            this.$message({
                                    message: '获取地区信息失败',
                                    type: 'warning'
                            });
                        }
                    }
                });
            },
            getRotationCharts(){
                let that = this;
                this.$httpservice.get('api/RotationChart/list',{activate:true,area:this.area?.value}).then(result => {
                    if(result.status==200){
                        if(result.body.code=='200'){
                            that.rotationCharts = result.body.data;
                        }else{
                            this.$message({
                                    message: '获取地区信息失败',
                                    type: 'warning'
                            });
                        }
                    }
                });
            }
        }

    }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){
    .news-content-mobile{
        display: none;
    }
    .carousel{
        height: 58vh;
        img{
            width: 100%;
            height: 100%;
        }
    }

    .location-group{
        padding: 1.5rem 8.6rem 1.5rem 8.6rem;
        .location-container{
            display: flex;
            flex-wrap:wrap;
            .location-item{
                margin-right: 1rem;
                margin-top: 1rem;
            }
        }
    }

    .card-content{
   
        display: flex;align-items: center;
        .image{
            flex: 1;
            img{
                width: 8rem;height: 8rem;margin-right: 0.5rem;
            }
        }
        .content{
            flex: 4;
            .title{
                font-size: 1.5rem;font-weight: 400;color: #333333;
            }
            .time{
                text-align: right;color: #44AE3D;padding-top: 1rem;
            }
            .desrciption{
                margin-top: 1.5rem;margin-bottom: 1.5rem;font-weight: 400;font-size: 1.3rem;line-height: 2rem;color: #999999;height: 4rem;overflow: hidden;text-overflow: ellipsis; width: 32vw; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;word-break: break-all;
            }
            .action{
                text-align: right;
            }
        }
    }

}

@media screen and (max-width:1000px) {
    .carousel{
        img{
            width: 100%;
            height: 10.375rem;
        }
    }

    .news-content{
        display: none;
    }

    .card-content{
   
        .image{
            img{
                width: 100%;margin-right: 0.5rem;
            }
        }
        .content{
            .title{
                font-size: 1.5rem;font-weight: 400;color: #333333;
            }
            .time{
                text-align: right;color: #44AE3D;padding-top: 1rem;
            }
            .desrciption{
                margin-top: 1.5rem;margin-bottom: 1.5rem;font-weight: 400;font-size: 1.3rem;line-height: 2rem;color: #999999;height: 4rem;overflow: hidden;text-overflow: ellipsis; width: 100%; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;word-break: break-all;
            }
            .action{
                text-align: right;
            }
        }
    }
}

@media screen and (max-aspect-ratio:16/16){
    .carousel{
        img{
            width: 100%;
            height: 10.375rem;
        }
    }

    .news-content{
        display: none;
    }

    .card-content{
   
        .image{
            img{
                width: 100%;margin-right: 0.5rem;
            }
        }
        .content{
            .title{
                font-size: 1.5rem;font-weight: 400;color: #333333;
            }
            .time{
                text-align: right;color: #44AE3D;padding-top: 1rem;
            }
            .desrciption{
                margin-top: 1.5rem;margin-bottom: 1.5rem;font-weight: 400;font-size: 1.3rem;line-height: 2rem;color: #999999;height: 4rem;overflow: hidden;text-overflow: ellipsis; width: 100%; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;word-break: break-all;
            }
            .action{
                text-align: right;
            }
        }
    }
}


.top-news-item{
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    
    .btn{
        flex:1
    }
    .content{
        flex: 5;
        color: #999999;overflow: hidden;text-overflow: ellipsis; width: 10vw; 
        display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;word-break: break-all;
        cursor: pointer;
    }
}
.top-news{
    margin-top: 1rem;
    background-color: #F5F7FB;
    .title{
        background-color: #408F21;height: 3.125rem;color: white;display: flex;justify-content: center;align-items: center;
    }
}
.card-Container{
    margin-top: 1rem;
    background-color: #F5F7FB;
}





.search{
    display: flex;
    justify-content: center;
    .item{
        margin-right: 1rem;
    }
    div{
        width: 40%;
        display: flex;
        justify-content: center;
    }
}

.table-container{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.table-content{
   width: 90%;
   text-align: center;
   margin-top: 1rem;
}

</style>
  