<template>
    <div class="page" v-loading.fullscreen.lock="fullscreenLoading">
        <div style="width: 100%;display: flex;justify-content: center;" v-if="!fullscreenLoading">
            <div style="width: 65%;">
                <div  id="pdf-details">
                    <div class="pdf-details" style="display: flex;justify-content: left;align-items: center;">
                        <img style="height: 4rem;margin-right: 3rem;" src="../../../assets/logo.png">
                        <label style="font-size: 1.5rem;color: #60AB42;" v-if="booking.bookingStatus==0">{{$i18n.locale=='en'?"STATUS: Processing":"狀態：處理中"}}</label>
                        <label style="font-size: 1.5rem;color: #60AB42;" v-if="booking.bookingStatus==1">{{$i18n.locale=='en'?"STATUS: Confirmed":"狀態：已確認"}}</label>
                        <label style="font-size: 1.5rem;color: #60AB42;" v-if="booking.bookingStatus==2">{{$i18n.locale=='en'?"STATUS: Cancelled":"已取消"}}</label>
                    </div>
                    <div class="dotted pdf-details"></div>
                    <el-row class="pdf-details" :gutter="48">
                        <el-col :span="6">
                            <div>
                                <label class="key">{{$t('voucher.bookingNo')}}</label><br>
                                <label class="value">{{booking.id}}</label>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div>
                                <label class="key">{{$t('voucher.invoiceNo')}}</label><br>
                                <label class="value">{{booking.bookingNo}}</label>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div>
                                <label class="key">{{$t('voucher.tourCode')}}</label><br>
                                <label class="value">{{booking.trip?.tourCode}}</label>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div>
                                <label class="key">{{$t('voucher.agentName')}}</label><br>
                                <label class="value">{{booking.account?.accountName}}</label>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="dotted pdf-details"></div>
                    <div class="line pdf-details">
                        <label class="key">{{$t('voucher.tourName')}}</label>
                        <label class="value">{{booking.trip?.tourName}}</label>
                    </div>
                    <!--
                    <div class="line">
                        <label class="key">Duration</label>
                        <label class="value">{{booking.trip?.duration}}</label>
                    </div>
                    -->
                    <div class="line pdf-details">
                        <label class="key">{{$t('voucher.tourDate')}}</label>
                        <label class="value">{{booking.tourDate.split(" ")[0]}}</label>
                    </div>
                    <!--
                    <div class="line">
                        <label class="key">Tour Language</label>
                        <label class="value">{{booking.tourLanguage}}</label>
                    </div>
                    -->
                    <div class="line pdf-details">
                        <label class="key">{{$t('voucher.consultant')}}</label>
                        <label class="value">{{booking.consultant}}</label>
                    </div>

                    <div class="line pdf-details">
                        <label class="key">{{$t('voucher.refNo')}}</label>
                        <label class="value">{{booking.refNo}}</label>
                    </div>
                    <div class="dotted pdf-details"></div>
                    <div>
                        <h3>{{$t('voucher.travellers')}}</h3>
                    </div>
                    <el-row class="pdf-details" :gutter="48" v-for="(item,index) of booking.passengers" :key="index+10086" style="margin-bottom: 1rem;">
                        <el-col :span="6">
                            <label class="key">{{$t('voucher.name')}}</label>
                            <label class="value">{{item.firstName+" "+item.lastName}}</label>
                        </el-col>
                        <!--
                        <el-col :span="6">
                            <label class="key">Contact</label>
                            <label class="value">{{item.contactPhoneNumber}}</label>
                        </el-col>
                        <el-col :span="6">
                            <label class="key">Email</label>
                            <label class="value">{{item.email}}</label>
                        </el-col>
                        -->
                        <el-col :span="6">
                            <label class="key">{{$t('voucher.gender')}}</label>
                            <label class="value">{{item.gender}}</label>
                        </el-col>
                    </el-row>
                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('voucher.passengerInfo')}}</h3>
                    </div>
                    <div class="line pdf-details">
                        <label class="key">{{$t('voucher.adult')}}</label>
                        <label class="value">{{booking.adultNum}}</label>
                    </div>

                    <div class="line pdf-details">
                        <label class="key">{{$t('voucher.childWithBed')}}</label>
                        <label class="value">{{booking.childWithBedNum}}</label>
                    </div>

                    <div class="line pdf-details">
                        <label class="key">{{$t('voucher.childWithoutBed')}}</label>
                        <label class="value">{{booking.childWithoutBedNum}}</label>
                    </div>

                    <!--
                    <div class="line">
                        <label class="key">Infant with Bed</label>
                        <label class="value">{{booking.infantwithBedNum}}</label>
                    </div>
                    <div class="line">
                        <label class="key">Infant without Bed</label>
                        <label class="value">{{booking.infantwithoutBedNum}}</label>
                    </div>
                    -->
                    <div class="dotted pdf-details"></div>

                    <div class="pdf-details">
                        <h3>{{$t('voucher.arrivalDetails')}}</h3>
                    </div>
                    <div class="value pdf-details">
                        <div v-for="(item,index) in booking.arrivalDetails" :key="index">
                            <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                        </div>
                    </div>
                    <div class="dotted pdf-details"></div>


                    <div class="pdf-details">
                        <h3>{{$t('voucher.departureDetails')}}</h3>
                    </div>
                    <div class="value pdf-details">

                        <div v-for="(item,index) in booking.departureDetails" :key="index">
                            <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                        </div>
                        
                    </div>
                    <div class="dotted pdf-details"></div>


                    <div class="pdf-details">
                        <h3>{{$t('voucher.noOfRooms')}}</h3>
                    </div>
                
                    <el-row :gutter="48"  class="pdf-details">
                        <el-col :span="8">
                            <label class="key">{{$t('voucher.singleRoom')}}</label>
                            <label class="value">x{{booking.singleRoom}}</label>
                        </el-col>
                        <el-col :span="8">
                            <label class="key">{{$t('voucher.twinRoom')}}</label>
                            <label class="value">x{{booking.twinRoom}}</label>
                        </el-col>
                        <!--
                        <el-col :span="6">
                            <label class="key">Double Room</label>
                            <label class="value">x{{booking.doubleRoom}}</label>
                        </el-col>
                        -->
                        <el-col :span="8">
                            <label class="key">{{$t('voucher.twinRoomExtraBed')}}</label>
                            <label class="value">x{{booking.tripleRoom}}</label>
                        </el-col>
                    </el-row>
                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('voucher.tippingPrepaid')}}</h3>
                    </div>
                    <div class="value pdf-details">
                        <span v-if="booking.tippingPrepaid">{{$t('voucher.yes')}}</span>
                        <span v-if="!booking.tippingPrepaid">{{$t('voucher.no')}}</span>
                    </div>

                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('voucher.compulsoryProgramPrepaid')}}</h3>
                    </div>
                    <div class="value pdf-details">
                        <span v-if="booking.compulsoryPrepaid">{{$t('voucher.yes')}}</span>
                        <span v-if="!booking.compulsoryPrepaid">{{$t('voucher.no')}}</span>
                    </div>

                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('voucher.extraItemDescription')}}</h3>
                    </div>
                    <div class="value pdf-details">
                        <span>{{booking.extraItemDescription}}</span>
                    </div>

                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('voucher.pickupPoint')}}</h3>
                    </div>
                    <div class="value pdf-details" v-html="booking.trip?.pickupPoint"></div>

                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('voucher.remark')}}</h3>
                    </div>
                    <div class="value pdf-details" v-html="booking.trip?.remark"></div>


                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('bookingSummary.preHotel')}}</h3>
                    </div>
                    <div class="value pdf-details">
                        <div v-for="(item,index) in booking.preTourAccommodations" :key="index">
                            <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                        </div>
                    </div>


                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('bookingSummary.postHotel')}}</h3>
                    </div>
                    <div class="value pdf-details">
                        <div v-for="(item,index) in booking.postTourAccommodations" :key="index">
                            <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passenger.firstName+" "+item.passenger.lastName+")"}}</label>
                        </div>
                    </div>


                    <div class="dotted pdf-details"></div>
                    <div  class="pdf-details">
                        <h3>{{$t('voucher.tourGuide')}}</h3>
                    </div>
                    <div class="value pdf-details"><label>{{booking.tourGuide}}</label></div>

                    <div class="dotted pdf-details"></div>
                    <div class="pdf-details">
                        <h3>{{$t('voucher.scheduling')}}</h3>
                    </div>
                    
                    <div class="pdf-details" style="margin-bottom: 2rem;" v-for="(item) of booking.trip?.schedulings" :key="item.sort">
                        <div style="color: #167D5B;font-size: 1.4rem;margin-bottom: 0.5rem;font-weight: 500;">{{ item.sort+". "+item.title }}</div>
                        <div style="color:#000000;font-size: 1.4rem;" v-html="item.content"></div>
                        <!--<div style="color:#000000;font-size: 1.4rem;" >{{ item.content }}</div>-->
                    </div>
                    
                    <div class="dotted pdf-details"></div>
                    <!--
                    <div>
                        <h3>Notice</h3>
                    </div>
                    <div class="value" v-html="booking.trip?.notice"></div>
                    <div class="dotted"></div>
                    -->
                </div>
                
                <div style="text-align: center;margin-top: 1rem;">
                    <el-button type="primary" @click="downloadPDFClick">{{$t('voucher.downloadPDF')}}</el-button>
                </div>
            </div>
            
        </div>
        
    </div>
  </template>
  
  <script>
  import htmlPdf from '@/service/pdf';
  export default {
    props:{BookingId:null},
    name: 'voucher-page',
    data(){
        return {
            booking:new Object(),
            fullscreenLoading:true
        }
    },
    mounted(){
        this.getBookingDetails(this.BookingId);
    },
    methods:{
        getBookingDetails(id){
            this.fullscreenLoading=true;
            let that = this;
            this.$httpservice.get('api/Booking/Get',{id:id}).then(result => {
                this.fullscreenLoading=false;
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.booking = result.body.data;
                        
                    }else{
                        that.$message({
                                message: 'Get Booking info Failed',
                                type: 'warning'
                        });
                    }
                }
            });
        },
        downloadPDFClick(){
            const lableList = document.getElementsByClassName('pdf-details');
            htmlPdf("Voucher", document.querySelector('#pdf-details'),lableList);
        }
    }
   
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    .page{
        padding: 1rem;

        .dotted {
            margin-top: 1rem;
            margin-bottom: 2rem;
            border-bottom: 1px dotted #000;
            width: 100%;
        }
        .key{
            color: black;margin-right: 1rem;font-size: 1.3rem;font-weight: bold;
        }
        .value{
            //color:#999999;font-size: 1rem;
            color:#000000;font-size: 1.3rem;
        }
        .line{
            margin-top: 1.2rem;
            margin-bottom: 1rem;
        }
    }
  </style>
  