<template>
    <div>
        <div style="display: flex;align-items: center;height: 10rem; padding-top: 3rem;padding-bottom: 3rem;">
            <div class="logo">
                <img src="../assets/logo.png">
            </div>
            
            <div class="links">
                <div class="line">                   
                    <div><a>{{$t('home.news')}}</a></div>
                    <div><a>{{$t('home.inbound')}}</a></div>                  
                </div>
                <div class="line">
                    <div><a>{{$t('home.outbound')}}</a></div>                    
                    <div><a>{{$t('home.mybooking')}}</a></div>
                </div>
                <div class="line">
                    <div><a>{{$t('home.commission')}}</a></div>
                    <div><a>{{$t('home.contractus')}}</a></div>
                </div>
            </div>
            
        </div>
    </div>
    
</template>

<script>
export default {
name: 'footer-component'

}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){
    .logo{
        padding-left: 5rem;
        flex: 1;
        img{
            width: 6rem;
        }
    }
   .links{
        flex: 4;
     .line{
        margin: 1rem;
        display: flex;
        div{
            width: 8rem;
            margin-left: 12rem;
            margin-right: 5rem;
        }
     }
   }
}

@media screen and (max-width:1000px){
    .logo{
        padding-left: 2rem;
        flex: 1;
        img{
            width: 3rem;
        }
    }
   .links{
        flex: 4;
        .line{
            margin: 1rem;
            display: flex;
            div{
               flex: 1;
            }
        }
   }
}

@media screen and (max-aspect-ratio:16/16) {
    .logo{
        padding-left: 2rem;
        flex: 1;
        img{
            width: 3rem;
        }
    }
   .links{
        flex: 4;
        .line{
            margin: 1rem;
            display: flex;
            div{
               flex: 1;
            }
        }
   }
}

</style>
  