<template>
  <div class="page">
      <div>
        <div class="title">
          <h1>Please Select your district</h1>
        </div>
        <div class="location-group">
          <el-radio-group v-model="selectedArea" @change="selectedAreaChange"  size="large">
              <div class="location-container">
                  <el-radio-button v-for="(item,index) in accountAreas" :key="index"  class="location-item" :label="item.value">{{ item.englishName }}</el-radio-button>
                  
              </div>
              
          </el-radio-group>
      </div>
      </div>
      
  </div>
</template>
  
<script>
  import Vue from 'vue';
  export default {
    name: 'select-account-area',
    data(){
        return {
            accountAreas:[],
            selectedArea:""
        };
    },
    mounted(){
        this.getAccountAreas();
    },
    methods:{
        getAccountAreas(){
          let that = this;
          this.$httpservice.get('api/common/GetAccountArea',).then(result => {
            if(result.status==200){
              if(result.body.code=='200'){
                that.accountAreas = result.body.data;
              }else{
                this.$message({
                      message: 'Get Area List Failed',
                      type: 'warning'
                });
              }
            }
          });
        },
        selectedAreaChange(e){
            this.selectedArea = e;
            Vue.prototype.$district=e;
            this.$router.push("/home");
        }
    }
   
  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .page{
      background: url("../../../assets/imgs/login_background.png");
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  

  @media screen and (min-aspect-ratio:16/16) and (min-width:1000px){
    .title{
      text-align: center;color: white;
    }
    .location-group{
      padding: 1.5rem 8.6rem 1.5rem 8.6rem;
      .location-container{
          display: flex;
          flex-wrap:wrap;
          .location-item{
              margin-right: 1rem;
              margin-top: 1rem;
          }
      }
    }
  }

  @media screen and (max-width:1000px){
    .title{
      text-align: center;color: white;font-size: 1rem;
    }
    .location-group{
      padding: 0.5rem;
      .location-container{
          display: flex;
          flex-wrap:wrap;
          .location-item{
              margin-right: 1rem;
              margin-top: 1rem;
          }
      }
    }
    
  }

  @media screen and (max-aspect-ratio:16/16) {
    .title{
      text-align: center;color: white;font-size: 1rem;
    }
    .location-group{
      padding: 0.5rem;
      .location-container{
          display: flex;
          flex-wrap:wrap;
          .location-item{
              margin-right: 1rem;
              margin-top: 1rem;
          }
      }
    }
    
  }
</style>
  