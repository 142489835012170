<template>
    <div style="padding-top: 1rem;">
        <div  v-if="!showVoucher&&!showBookingSummary&&!showInvoice">        
            <el-row class="search-term" :gutter="24" style="margin-top: 3rem;">
                <el-col :span="4" :offset="2"><el-input v-model="searchBookingId" type="number"  :placeholder="$t('mybooking.bookingID')"></el-input></el-col>
                <el-col :span="4"><el-input v-model="searchRefNo"  :placeholder="$t('mybooking.bookingRefNo')"></el-input></el-col>
                <el-col :span="4"><el-input v-model="searchTourName"  :placeholder="$t('mybooking.tourName')"></el-input></el-col>
                <el-col :span="4"><el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="searchTourDate" style="width: 100%;" type="date" :placeholder="$t('mybooking.tourDate')"></el-date-picker></el-col>
                
                <el-col :span="4">
                    <el-button type="primary" size="small" @click="getBookingList(1)">{{$t('mybooking.search')}}</el-button>
                    <el-button type="primary" size="small" @click="ExportList()" :loading="exporting">{{$t('mybooking.export')}}</el-button>
                </el-col>
                
            </el-row>
            
            <div class="search-term-mobile">
                <el-row :gutter="24" style="margin-top: 1rem;">
                    <el-col :span="12"><el-input v-model="searchBookingId" type="number"  :placeholder="$t('mybooking.bookingID')"></el-input></el-col>
                    <el-col :span="12"><el-input v-model="searchRefNo"  :placeholder="$t('mybooking.bookingRefNo')"></el-input></el-col>                
                </el-row>
                <el-row style="margin-top: 1rem;">
                    <el-col :span="12"><el-input v-model="searchTourName"  :placeholder="$t('mybooking.tourName')"></el-input></el-col>
                    <el-col :span="12"><el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="searchTourDate" style="width: 100%;" type="date" :placeholder="$t('mybooking.tourDate')"></el-date-picker></el-col>
                    
                </el-row>
                <el-row style="margin-top: 1rem;">
                    <el-col :span="12">
                        <el-button type="primary" size="small" @click="getBookingList(1)">{{$t('mybooking.search')}}</el-button>
                        <el-button type="primary" size="small" @click="ExportList()" :loading="exporting">{{$t('mybooking.export')}}</el-button>
                    </el-col>
                </el-row>
            </div>

            <div class="table-container">
                <div class="table-content">
                    <el-table
                        :data="bookingTableData"
                        border
                        v-loading="loadingTableData"
                        height="60vh"
                        style="width: 100%;">
                        <el-table-column
                            prop="id"
                            :label="$t('mybooking.bookingID')">
                        </el-table-column>
                        <el-table-column
                            :label="$t('mybooking.tourName')"
                            width="180">
                            <template slot-scope="scope">
                                {{scope.row.trip?.tourName}}                           
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('mybooking.tourDate')">
                            <template slot-scope="scope">
                                {{scope.row.tourDate.split(' ')[0]}}                           
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('mybooking.pax')">
                            <template slot-scope="scope">
                                {{scope.row.passengers.length}}                           
                            </template>
                        </el-table-column>
                        
                        <!--
                        <el-table-column
                            prop="address"
                            label="Amount">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="Invoice">
                        </el-table-column>
                        -->

                        <el-table-column
                            width="160"
                            :label="$t('mybooking.paymentStatus')">
                            <template slot-scope="scope">
                                <label v-if="scope.row.paymentStatus==0">Unpaid</label>    
                                <label v-if="scope.row.paymentStatus==1">Partial Paid</label>     
                                <label v-if="scope.row.paymentStatus==2">Fully Paid</label>              
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="180"
                            :label="$t('mybooking.passengerName')">
                            <template slot-scope="scope">
                                <div v-for="(item,index) in scope.row.passengers" :key="index"><label>{{item.firstName+" "+item.lastName}}</label></div>
                                             
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="refNo"
                            :label="$t('mybooking.yourRef')">
                        </el-table-column>
                        <el-table-column
                            prop="consultant"
                            :label="$t('mybooking.consultant')">
                        </el-table-column>
                        <el-table-column
                            prop="createdOn"
                            :label="$t('mybooking.bookingDate')">
                        </el-table-column>
                        <el-table-column
                            label="Status">
                            <template slot-scope="scope">
                                <label v-if="scope.row.bookingStatus==0">{{$t('mybooking.processing')}}</label>    
                                <label v-if="scope.row.bookingStatus==1">{{$t('mybooking.confirmed')}}</label>     
                                <label v-if="scope.row.bookingStatus==2">{{$t('mybooking.cancelled')}}</label>              
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="200"
                            label="Action">
                            <template slot-scope="scope">
                                <el-button type="text" @click="viewClick(scope.row.id)">{{$t('mybooking.view')}}</el-button>
                                <el-button type="text" @click="voucherClick(scope.row.id)">{{$t('mybooking.voucher')}}</el-button>
                                <el-button type="text" @click="invoiceClick(scope.row.id)">{{$t('mybooking.invoice')}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        class="table-content"
                        background
                        layout="prev, pager, next"
                        @size-change="handleBookingsPageSizeChange"
                        @current-change="handleBookingsCurrentPageChange"
                        :current-page="currenBookingPageIndex"
                        :page-size="bookingPageSize"
                        :total="bookingTotalCount">
                    </el-pagination>
                </div>
            
            </div>
        </div>
        <div v-if="showVoucher">
            <div>
                <el-button icon="el-icon-back" style="padding-left: 10rem;font-size: 1.2rem;" type="text" @click="showVoucher=false">{{$t('mybooking.back')}}</el-button>
            </div>
            <Voucher :BookingId="selectedBookingId"></Voucher>
        </div>
        <div v-if="showBookingSummary">
            <div>
                <el-button icon="el-icon-back" style="padding-left: 6rem;font-size: 1.2rem;" type="text" @click="showBookingSummary=false">{{$t('mybooking.back')}}</el-button>
            </div>
            <BookingSummary :BookingId="selectedBookingId"></BookingSummary>
        </div>
        <div v-if="showInvoice">
            <div>
                <el-button icon="el-icon-back" style="padding-left: 6rem;font-size: 1.2rem;" type="text" @click="showInvoice=false">{{$t('mybooking.back')}}</el-button>
            </div>
            <BookingInvoice :BookingId="selectedBookingId"></BookingInvoice>
        </div>
        <FooterPage></FooterPage> 
    </div>
    
</template>
  
<script>
import FooterPage from '../../../components/footer.vue';
import Voucher from './voucher.vue';
import BookingSummary from '../../booking/booking-summary.vue';
import BookingInvoice from '../../booking/booking-invoice.vue';
export default {
    components:{FooterPage,Voucher,BookingSummary,BookingInvoice},
    name: 'my-booking',
    data(){
      return {
        searchBookingId:null,
        searchRefNo:null,
        searchTourName:null,
        searchTourDate:null,
        bookingTableData:[],
        bookingPageSize:5,
        bookingTotalCount:0,
        currenBookingPageIndex:1,
        showVoucher:false,
        selectedBookingId:0,
        loadingTableData:false,
        exporting:false,
        showBookingSummary:false,
        showInvoice:false
      }
    },
    mounted(){
        this.getBookingList(1);
    },
    methods:{
        invoiceClick(id){
            this.selectedBookingId = id;
            this.showInvoice=true;
        },
        voucherClick(id){
            this.selectedBookingId = id;
            this.showVoucher=true;
            
        },
        viewClick(id){
            this.selectedBookingId = id;
            this.showBookingSummary=true;
            
        },
        handleBookingsCurrentPageChange(e){
            this.getBookingList(e);
        },
        handleBookingsPageSizeChange(e){
            this.bookingPageSize=e;
            this.getBookingList(1);
        },
        getBookingList(pageIndex){
            let that = this;
            this.loadingTableData = true;
            this.$httpservice.get('api/Booking/List',{
                pageIndex:pageIndex,
                pageSize:this.bookingPageSize,
                bookingId:this.searchRefNo,
                tourDate:this.searchTourDate,
                tourName:this.searchTourName,
                id:this.searchBookingId
            }).then(result => {
                this.loadingTableData = false;
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.bookingTableData = result.body.data;
                        that.bookingTotalCount = result.body.totalCount;
                    }else{
                        that.$message({
                            message: 'Operation Failed',
                            type: 'warning'
                        });
                    }
                }
            });
        },
        ExportList(){
            
            let that = this;
            
            this.exporting = true;
            this.$httpservice.get('api/Booking/Export',{
                bookingId:this.searchRefNo,
                tourDate:this.searchTourDate,
                tourName:this.searchTourName
            }).then(result => {
                that.exporting = false;
                if(result.body.code=='200'){
                    let fileUrl = result.body.data;
                    window.open(fileUrl);
                }else{
                    that.$message({
                        message: 'Operation Failed',
                        type: 'warning'
                    });
                }
            });
            
            //this.$download("/api/Booking/Export", "text.xlsx");
        }
    }
   
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){

    .search-term-mobile{
        display: none;
    }
}

@media screen and (max-width:1000px){
    .search-term{
        display: none;
    }
    .search-term-mobile{
        padding: 1rem;

    }
}

@media screen and (max-aspect-ratio:16/16) {
    .search-term{
        display: none;
    }
    .search-term-mobile{
        padding: 1rem;
    }
}

.table-container{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.table-content{
   width: 90%;
   text-align: center;
   margin-top: 1rem;
}

</style>
  