<template>
    <div>
        <div v-if="!isBooking">
            <div class="carousel">
                <el-carousel>
                    <el-carousel-item v-for="(item,index) in rotationCharts" :key="index">
                        <a>
                            <img :src="item.picture.path">
                        </a>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div v-if="!showDetails">
                <div class="location-group">
                    <el-radio-group v-model="selectedCategory" @change="selectedCategoryChange"  size="small">
                        <div class="location-container">
                            <el-radio-button v-for="(item,index) in tourCatagories" :key="index"  class="location-item" :label="item.value">{{ $i18n.locale=='en'?item.englishName:item.chineseName }}</el-radio-button>    
                        </div>    
                    </el-radio-group>
                    <el-divider></el-divider>
                    <div class="location-container">
                        <el-radio-group v-model="selectedTag" @change="selectedTagChange"  size="small">
                            <el-radio-button v-for="(item,index) in allTags" :key="index"  class="location-item" :label="item.id" >{{ $i18n.locale=='en'?item.englishName:item.name }}</el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
               
                <div class="search">
                    <div>
                        <el-input class="item" v-model="searchTourCode" :placeholder="$t('inboundTour.tourCode')" ></el-input> 
                        <el-input class="item" v-model="searchTourName" :placeholder="$t('inboundTour.englishNameOrChineseName')"></el-input> 
                        <el-button class="search-item" type="primary" @click="getTours(1)">{{$t('inboundTour.search')}}</el-button>
                    </div>
                </div>
                <div class="table-container">
                    <div style="width: 90%;">
                        <div class="table-content">
                            <el-table
                                v-if="!visitorMode"
                                v-loading="loadingTable"
                                :data="tourTableData"
                                border
                                height="60vh"
                                style="width: 100%;min-height: 30vh;max-height: 60vh;">
                                <el-table-column
                                    prop="tourCode"
                                    :label="$t('inboundTour.tourCode')"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="tourName"
                                    :label="$t('inboundTour.tourName')">
                                </el-table-column>
                                <el-table-column
                                    prop="countryAndTour"
                                    :label="$t('inboundTour.countryAndTour')"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="duration"
                                    :label="$t('inboundTour.duration')">
                                </el-table-column>
                                <el-table-column
                                    prop="priceForm"
                                    :label="$t('inboundTour.priceForm')">
                                </el-table-column>
                               
                                <el-table-column
                                    prop="startCity"
                                    :label="$t('inboundTour.startCity')">
                                </el-table-column>
                                <el-table-column
                                    prop="endCity"
                                    :label="$t('inboundTour.endCity')">
                                </el-table-column>
                                <!--
                                <el-table-column
                                    label="Poster"
                                    width="100">
                                    <template slot-scope="scope">

                                        <el-link v-if="scope.row.poster" :href="scope.row.poster.fileLib.path" target="_blank"><i style="font-size:20px" class="el-icon-download"></i></el-link>
                                    </template>
                                </el-table-column>
                                -->
                                <el-table-column
                                    label="Action"
                                    width="180">
                                    <template slot-scope="scope">
                                        <el-button  type="primary" @click="handleViewClick(scope.row)" size="small">{{$t('inboundTour.view')}}</el-button>
                                        <el-button v-if="showAction"  type="primary" @click="handleBookClick(scope.row)" size="small">{{$t('inboundTour.book')}}</el-button>
                                        
                                    </template>
                                </el-table-column>
                            </el-table>
                            
                        </div>
                        <div v-loading.fullscreen.lock="loadingTable">
                            <el-row class="table-visit"  v-if="visitorMode" :gutter="48" >
                                <el-col style="margin-bottom: 2rem;" :span="6" v-for="(item, index) in tourTableData" :key="index" >
                                    <el-card :body-style="{ padding: '0px' }">
                                    <img :src="item.picture?.path" class="image">
                                    <div style="padding: 14px;">
                                        <span>{{item.tourName}}</span>
                                        <div class="bottom clearfix">
                                            <time class="time">{{item.countryAndTour}}</time>
                                            <el-button type="text" class="button" @click="handleViewClick(item)">{{$t('inboundTour.ViewDetail')}}</el-button>
                                        </div>
                                    </div>
                                    </el-card>
                                </el-col>
                            </el-row>
                            <el-row class="table-visit-mobile" v-if="visitorMode" :gutter="12" >
                                <el-col style="margin-bottom: 2rem;" :span="12" v-for="(item, index) in tourTableData" :key="index" >
                                    <a  @click="handleViewClick(item)">
                                        <el-card :body-style="{ padding: '0px' }">
                                            <img :src="item.picture?.path" class="image">
                                            <div style="padding: 14px;">
                                                <span style="height:3rem;display: block;overflow: hidden;">{{item.tourName}}</span>
                                                <div class="bottom clearfix">
                                                    <time class="time">{{item.countryAndTour}}</time>
                                                    
                                                </div>
                                            </div>
                                        </el-card>
                                    </a>
                                </el-col>
                            </el-row>
                        </div>
                        

                        <el-pagination
                            class="table-content"
                            background
                            layout="prev, pager, next"
                            @size-change="handleToursPageSizeChange"
                            @current-change="handleToursCurrentPageChange"
                            :current-page="currentToursPage"
                            :page-size="toursPageSize"
                            :page-sizes="[10, 50, 100, 200]"
                            :total="tourTotalCount">
                        </el-pagination>
                    </div>
                   
                
                </div>
            </div>
           
            <div v-if="showDetails" style="display: flex;justify-content: center;margin-top: 2rem;">
                
                <div  class="details">
                    <div>
                        <el-button type="text" icon="el-icon-back" style="margin: 1rem;" @click="showDetails=false">{{$t('inboundTour.back')}}</el-button>
                    </div>

                    <el-tabs type="border-card">
                        <el-tab-pane :label="$t('inboundTour.dateAndPrice')">
                            <div>
                            
                                <div style="display: flex;background-color: #F5F7FB;min-height: 8rem;">
                                    <div style="flex: 1;display: flex;border-right: 1px solid #9AA3B9;">
                                        <div style="flex: 1;display: flex;justify-content: right;align-items: center;font-size: 2rem;font-weight: bold;padding-right: 1.5rem;">
                                            <i class="el-icon-time" style="color: #60AB42;font-weight: bold;"></i>
                                        </div>
                                        <div style="flex: 1;display: flex;align-items: center;">
                                            <div>
                                                <div style="color: #000000;font-size: 1.2rem;font-weight: bold;margin-bottom: 0.2rem;">{{currentBookingTour.duration+$t('inboundTour.day')}}</div>
                                                <div style="color: #9AA3B9;font-size: 1.2rem;">{{ $t('inboundTour.daysOfSchedule') }}</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div style="flex: 1;display: flex;border-right: 1px solid #9AA3B9;">
                                        <div style="flex: 1;display: flex;justify-content: right;align-items: center;font-size: 2rem;font-weight: bold;padding-right: 1.5rem;">
                                            <i class="el-icon-office-building" style="color: #60AB42;font-weight: bold;"></i>
                                        </div>
                                        <div style="flex: 1;display: flex;align-items: center;">
                                            <div>
                                                <div style="color: #000000;font-size: 1.2rem;font-weight: bold;margin-bottom: 0.2rem;">{{currentBookingTour.startCity}}</div>
                                                <div style="color: #9AA3B9;font-size: 1.2rem;">{{$t('inboundTour.startCity')}}</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div style="flex: 1;display: flex;border-right: 1px solid #9AA3B9;">
                                        <div style="flex: 1;display: flex;justify-content: right;align-items: center;font-size: 2rem;font-weight: bold;padding-right: 1.5rem;">
                                            <i class="el-icon-position" style="color: #60AB42;font-weight: bold;"></i>
                                        </div>
                                        <div style="flex: 1;display: flex;align-items: center;">
                                            <div>
                                                <div style="color: #000000;font-size: 1.2rem;font-weight: bold;margin-bottom: 0.2rem;">{{currentBookingTour.endCity}}</div>
                                                <div style="color: #9AA3B9;font-size: 1.2rem;">{{$t('inboundTour.endCity')}}</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div style="margin-top: 2rem;border: 1px solid #CDD0D6 ;">
                                    <div style="color: black;text-align: center;font-size: 1.2rem;font-weight: bolder;height: 3rem;display: flex;justify-content: center;align-items: center;">{{$t('inboundTour.priceList')}}</div>
                                </div>
                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Tour Fee</div>
                                            <div>成人报名费</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{currentBookingTour.tourFee}}</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Child with bed</div>
                                            <div>儿童占床</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{ currentBookingTour.childWithBed }}</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Child without bed</div>
                                            <div>儿童不占床</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{currentBookingTour.childWithoutBed}}</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Infant with bed</div>
                                            <div>婴儿占床</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{ currentBookingTour.infantWithBed }}</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Infant without bed</div>
                                            <div>婴儿不占床</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{ currentBookingTour.infantWithoutBed }}</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Simple Sup</div>
                                            <div>单人房间差</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{ currentBookingTour.simpleSup }}</div>
                                        </div>
                                    
                                    </div>
                                </div>

                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Compulsory Service Fee</div>
                                            <div>综合服务费</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{ currentBookingTour.comprehensiveServiceFee }}</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div style="border: 1px solid #CDD0D6 ;height: 4.5rem; display: flex;">
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;border-right: 1px solid #CDD0D6;">
                                        <div>
                                            <div>Compulsory Program Fee</div>
                                            <div>指定自费项目费用</div>
                                        </div>
                                    
                                    </div>
                                    <div style="flex: 1;color:#666666;display: flex;align-items: center;justify-content: center;">
                                        <div>
                                            
                                            <div>{{ currentBookingTour.compulsoryProgramFee }}</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div style="margin-top: 2rem;">
                                    <h3>{{$t('inboundTour.itineraryFeatures')}}</h3>
                                    <div v-html="currentBookingTour.itineraryFeatures"></div>
                                </div>
                                <div style="margin-top: 2rem;">
                                    <h3>{{$t('inboundTour.departureDate')}}</h3>
                                    <div v-html="currentBookingTour.frequency"></div>
                                </div>
                                <div style="margin-top: 2rem;">
                                    <h3>{{$t('inboundTour.remark')}}</h3>
                                    <div v-html="currentBookingTour.remark"></div>
                                </div>
                                <div style="margin-top: 2rem;">
                                    <h3>{{$t('inboundTour.pickUpPoint')}}</h3>
                                    <div v-html="currentBookingTour.trip?.pickupPoint"></div>
                                </div>
                                <div style="margin-top: 3rem;">
                                    <el-link style="color: #60AB42;font-size: 1.2rem;" v-if="currentBookingTour.poster" :href="currentBookingTour.poster.fileLib.path" target="_blank"><i style="font-size:20px" class="el-icon-download"></i><label style="margin-left: 0.5rem;">{{$t('inboundTour.downloadPoster')}}</label></el-link>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane :label="$t('inboundTour.scheduling')">

                            <div  v-for="(item,index) of currentBookingTour.schedulings" :key="index" style="display: flex;">
                                <div style="display: flex;flex:1;justify-content: center;align-items: center;">
                                    <h4 v-if="$i18n.locale=='tw'">{{ '第'+item.sort+'天' }}</h4>
                                    <h4 v-if="$i18n.locale=='en'">{{ 'Day '+ item.sort }}</h4>
                                </div>

                                <div  style="display: flex;flex:4;align-items: center;">
                                    <div>
                                        <div><h3>{{item.title}}</h3></div>
                                        <div>
                                            {{ item.content }}
                                        </div>
                                    </div>
                                
                                </div>
                                
                            </div>

                        </el-tab-pane>
                        <el-tab-pane :label="$t('inboundTour.noticeToAttendees')">
                            <div>
                                <div v-html="currentBookingTour.notice"></div>
                                
                            </div>
                        </el-tab-pane>
                        
                    </el-tabs>
                </div>
               
              
            </div>
            
        </div>
       <div v-if="isBooking"><bookingTour :tour="currentBookingTour" :isInbound="true" @onBookSuccess="onBookSuccessFun"></bookingTour></div>
        <FooterPage></FooterPage> 
    </div>
    
</template>
  
<script>

    import FooterPage from '../../../components/footer.vue';
    import bookingTour from '../../booking/booking-tour.vue'
    export default {
        components:{FooterPage,bookingTour},
        props:{
            Area:null
        },
        name: 'inbound-tour',
        watch:{

            Area:function(newValue,oldValue){
                console.log(111111111,oldValue);
                this.area = newValue;
                this.getTours(1);
                this.getRotationCharts();
                
			}
        },
        data(){
            return {
                searchTourName:null,
                searchTourCode:null,
                loadingTable:false,
                selectedCategory:"",
                tourTableData:[],
                isBooking:false,
                toursPageSize:16,
                tourTotalCount:0,
                currentToursPage:1,
                tourCatagories:[],
                rotationCharts:[],
                currentBookingTour:null,
                area:null,
                showDetails:false,
                showAction:true,
                visitorMode:true,
                loadingTrip:false,
                selectedTag:0,
                allTags:[]
            }
        },
        mounted(){
            this.area = this.Area;
            this.getTours(1);
            this.getTourCatagories();
            this.getRotationCharts();
            this.getAllTags();

            if(!this.$account){
                this.showAction = false;
                this.visitorMode = true;
            }else{
                this.showAction = true;
                this.visitorMode = false;
            }
        },
        methods:{
            getAllTags(){
                let that = this;
                this.$httpservice.get('api/tag/List',{pageIndex:0,pageSize:0,show:true}).then(result => {
                    that.loadingTable=false;
                    if(result.status==200){
                        if(result.body.code=='200'){
                            that.allTags = result.body.data;
                        }else{
                            that.$message({
                                message: 'Operation Failed',
                                type: 'warning'
                            });
                        }
                    }
                });
            },
            handleViewClick(e){
                this.showDetails=true;
                this.getTour(e.id);
            },
            getTour(id){
                let that = this;
                this.loadingTrip = true;
                this.$httpservice.get('api/Trip/Get',{id:id}).then(result => {
                    that.loadingTrip = false;
                    if(result.status==200){
                        if(result.body.code=='200'){
                            that.currentBookingTour = result.body.data;

                            if(that.$account){                      
                                that.currentBookingTour.poster = that.currentBookingTour.posters.find(x=>x.district==that.$account.area);                                
                            }else{
                                that.currentBookingTour.poster = that.currentBookingTour.posters.find(x=>x.district==that.$district);                               
                            }

                            let districtData = this.$district;
                            if(this.$account){
                                districtData = this.$account.area;
                            }

                            let priceSystem = this.currentBookingTour.priceSystems.find(x=>x.district ==districtData);
                            if(priceSystem){
                                this.currentBookingTour.simpleSup = priceSystem.simpleSup;
                                this.currentBookingTour.comprehensiveServiceFee = priceSystem.comprehensiveServiceFee;
                                this.currentBookingTour.compulsoryProgramFee = priceSystem.compulsoryProgramFee;
                            }
                            
                            //todo
                            let priceAndCommissionSettings = this.currentBookingTour.priceAndCommissionSettings.filter(x=>x.area ==districtData);
                            console.log(priceAndCommissionSettings);
                            let AdultPriceAndCommissionSetting = priceAndCommissionSettings.find(x=>x.passengerType=="Adult");
                            if(AdultPriceAndCommissionSetting){
                                this.currentBookingTour.tourFee = AdultPriceAndCommissionSetting.price;
                            }

                            let ChildWithBedPriceAndCommissionSetting = priceAndCommissionSettings.find(x=>x.passengerType=="Child"&&x.withBed==1);
                            if(ChildWithBedPriceAndCommissionSetting){
                                this.currentBookingTour.childWithBed = ChildWithBedPriceAndCommissionSetting.price;
                            }

                            let ChildWithoutBedPriceAndCommissionSetting = priceAndCommissionSettings.find(x=>x.passengerType=="Child"&&x.withBed==2);
                            if(ChildWithoutBedPriceAndCommissionSetting){
                                this.currentBookingTour.childWithoutBed = ChildWithoutBedPriceAndCommissionSetting.price;
                            }

                            
                            let infantWithBedPriceAndCommissionSetting = priceAndCommissionSettings.find(x=>x.passengerType=="Infant"&&x.withBed==1);
                            if(infantWithBedPriceAndCommissionSetting){
                                this.currentBookingTour.infantWithBed = infantWithBedPriceAndCommissionSetting.price;
                            }

                            let infantWithoutBedPriceAndCommissionSetting = priceAndCommissionSettings.find(x=>x.passengerType=="Infant"&&x.withBed==2);
                            if(infantWithoutBedPriceAndCommissionSetting){
                                this.currentBookingTour.infantWithoutBed = infantWithoutBedPriceAndCommissionSetting.price;
                            }
                            
                        }else{
                            that.$message({
                                message: 'Operation Failed',
                                type: 'warning'
                            });
                        }
                    }
                });
            },
            selectedTagChange(e){
                console.log(e);
                this.getTours(1);
            },
            selectedCategoryChange(e){
                console.log(e);
                this.getTours(1);
            },
            onBookSuccessFun(e){
                console.log(e);
                this.isBooking=!this.isBooking;
                this.getTours(1);
                this.$emit('onBookSuccess', null);
            },
            gotoBooking(){
                this.isBooking=!this.isBooking;
            },

            handleBookClick(trip){
                this.currentBookingTour = trip;
                this.isBooking=!this.isBooking;
            },
            handleToursPageSizeChange(e){
                this.toursPageSize = e;
                this.getTours(1);
            },
            handleToursCurrentPageChange(e){
                this.currentToursPage = e;
                this.getTours(e);
            },
            getTours(pageIndex){
                let that = this;
                this.loadingTable = true;
                let districtData = this.$district;
                if(this.$account){
                    districtData = this.$account.area;
                }
                this.$httpservice.get('api/Trip/List',{tourName:this.searchTourName,tourCode:this.searchTourCode,pageIndex:pageIndex,pageSize:this.toursPageSize,inbound:true,area:this.area?.value,category:this.selectedCategory,district:districtData,tagId:this.selectedTag}).then(result => {
                    if(result.status==200){
                        that.loadingTable = false;
                        if(result.body.code=='200'){
                            that.tourTableData = result.body.data;
                            that.tourTotalCount = result.body.totalCount;
                            if(that.$account){
                                that.tourTableData.forEach(tourData => {
                                    tourData.poster = tourData.posters.find(x=>x.district==that.$account.area);
                                });
                            }else{
                                that.tourTableData.forEach(tourData => {
                                    tourData.poster = tourData.posters.find(x=>x.district==that.$district);
                                });
                            }
                        }else{
                            that.$message({
                                message: 'Operation Failed',
                                type: 'warning'
                            });
                        }
                    }
                });
            },
            getTourCatagories(){
                let that = this;
                this.$httpservice.get('api/common/GetTourCatagory',{inbound:true}).then(result => {
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.tourCatagories = result.body.data;
                    }else{
                        this.$message({
                                message: 'Get Tour Catagories Failed',
                                type: 'warning'
                        });
                    }
                }
                });
            },
            getRotationCharts(){
                let that = this;
                this.$httpservice.get('api/RotationChart/list',{activate:true,area:this.area?.value}).then(result => {
                    if(result.status==200){
                        if(result.body.code=='200'){
                            that.rotationCharts = result.body.data;
                        }else{
                            this.$message({
                                    message: '获取地区信息失败',
                                    type: 'warning'
                            });
                        }
                    }
                });
            }
        }

    }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){
    .table-visit-mobile{
        display: none;
    }

    .image {
        width: 100%;
        height: 30vh;
        display: block;
    }

    .carousel{
        height: 58vh;
        img{
            width: 100%;
            height: 100%;
        }
    
    }

    .location-group{
        padding: 1.5rem 8.6rem 1.5rem 8.6rem;
        .location-container{
            display: flex;
            flex-wrap:wrap;
            .location-item{
                margin-right: 1rem;
                margin-top: 1rem;
            }
        }
    }

    .search{
        display: flex;
        justify-content: center;
        .item{
            flex: 2;
            margin-right: 1rem;
        }
        .search-item{
            flex: 1;
        }
        div{
            width: 40%;
            display: flex;
            justify-content: center;
        }
    }

    .details{
        width: 80%;
    }

}

@media screen and (max-width:1000px) {
    .table-visit{
        display: none;
    }

    .image {
        width: 100%;
        height: 7rem;
        display: block;
    }

    .carousel{
        img{
            width: 100%;
            height: 10.375rem;
        }
    }

    

    .location-group{
        padding: 1rem;
        .location-container{
            display: flex;
            flex-wrap:wrap;
            .location-item{
                margin-right: 0.3rem;
                margin-top: 1rem;
            }
        }
    }

    .search{
        display: flex;
        justify-content: center;
        .item{
            flex: 2;
            margin-right: 1rem;
        }
        .search-item{
            flex: 1;
        }
        div{
            width: 90%;
            display: flex;
            justify-content: center;
        }
    }

    .details{
        width: 95%;
    }

}

@media screen and (max-aspect-ratio:16/16) {
    .table-visit{
        display: none;
    }

    .image {
        width: 100%;
        height: 7rem;
        display: block;
    }

    .carousel{
        img{
            width: 100%;
            height: 10.375rem;
        }
    }

    

    .location-group{
        padding: 1rem;
        .location-container{
            display: flex;
            flex-wrap:wrap;
            .location-item{
                margin-right: 0.3rem;
                margin-top: 1rem;
            }
        }
    }

    .search{
        display: flex;
        justify-content: center;
        .item{
            flex: 2;
            margin-right: 1rem;
        }
        .search-item{
            flex: 1;
        }
        div{
            width: 90%;
            display: flex;
            justify-content: center;
        }
    }

    .details{
        width: 95%;
    }

}






.table-container{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.table-content{
   width: 100%;
   text-align: center;
   margin-top: 1rem;
}


.time {
    font-size: 13px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }


  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }

</style>
  