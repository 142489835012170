<template>
    <div class="page">
        <div>
            <div>
                <div>
                    <el-button type="text" icon="el-icon-back" style="margin: 1rem;" @click="back">{{ $t('newsInfo.back') }}</el-button>
                </div>
                <div class="info-content">
                    <div class="content-item" style="margin-bottom: 1rem;max-width: 80vw">
                        <img v-if="currentObject.picture" style="min-height: 30vh;min-width: 30vh;" :src="currentObject.picture.path"/>
                    </div>
                    <div class="content-item">
                        <h3>
                            {{ currentObject.title  }}
                        </h3>
                    </div>
                    <div class="content-item">
                        <h4>
                            {{ currentObject.subTitle  }}
                        </h4>
                    </div>
                    <div class="content-item">
                        <div v-html="currentObject.content">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props:{NewsID:null},
    name: 'news-info',
    mounted(){
        this.getNewsById();
    },
    data(){
        return{
            currentObject:new Object()
        }
    },
    methods:{
        getNewsById(){
            let that = this;
            this.$httpservice.get('api/news/get',{id:this.NewsID}).then(result => {
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.currentObject = result.body.data
                    }else{
                        that.$message({
                                message: 'Get News Information Failed',
                                type: 'warning'
                        });
                    }
                }
            });
        },
        back(){
            this.$emit("back",null);
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    .page{
        min-height: 60vh;
        padding-top: 2rem;

    }
    .content-item{
       display: flex;
       justify-content: center;
    }

    .info-content{
        padding: 1rem;
    }
  </style>
  