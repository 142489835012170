<template>
    <div class="page">
        <div class="container">
          <div class="title"><label>{{ $t("login.title") }}</label></div> 
          <el-input class="myinput"  :placeholder="$t('login.accountPlaceholder')" v-model="account"></el-input> 
          <el-input type="password" class="myinput" :placeholder="$t('login.passwordPlaceholder')" v-model="password"></el-input>
          <div class="btn"><el-button @click="login" style="width: 80%;" type="primary">{{ $t("login.btnText") }}</el-button> </div>
        </div>

    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  export default {
    name: 'login-page',
    data(){
      return{
        account:"",
        password:""
      }
    },
    methods:{
      login(){
        let that = this;
        if(this.account&&this.password){
          this.$httpservice.post("api/Account/Login",{loginName:this.account,password:this.password}).then(result=>{
            if(result.status==200){
              if(result.body.code=='200'){
                  Vue.prototype.$account = result.body.data;
                  that.$cookies.set("token", result.body.data.token,{expires: 0});
                  this.$router.push("/home")
              }else{
                this.$message({
                      message: '登录失败',
                      type: 'warning'
                });
              }
            }

          })
        
        }else{
          this.$message({
                    message: '请填写用户名和密码',
                    type: 'warning'
          });
        }
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .page{
      background: url("../../assets/imgs/login_background.png");
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .container{
        background-color: white;
        text-align: center;
        height: 30vh;
        width: 50wh;
        border: 0.5rem solid #51a06f;
        border-radius: 2rem;
        padding: 3rem 4rem 3rem 4rem;
        
        label{
          font-size: 1.5rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #167d5b;
          line-height: 1.3rem;
          
        }
        .title{
          margin-bottom: 2rem;
        }
        .myinput{
         
          margin-bottom: 2rem;
          width: 80%;
        }
        .btn{
          text-align: center;
          width: 100%;
        }
      }
    }
  </style>
  