import Vue from 'vue'
import Router from 'vue-router'

import home from '@/pages/home/home'
import login from '@/pages/login/login'
import selectAccountArea from '@/pages/home/components/select-account-area'
import examplePage from '@/pages/home/example'
Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/',
      name: 'default',
      component: home
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/home',
      name: 'home',
      component: home
    },
    {
      path: '/selectaccountarea',
      name: 'selectAccountArea',
      component: selectAccountArea
    },
    {
      path:'/example',
      name: 'example',
      component: examplePage
    }
  ]
});

/*
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
*/



