<template>
    <div style="overflow-x: hidden;">
      <div style="position: fixed;top: 0;left: 0;width: 100%;z-index: 100;">
        <div class="header">
          <div class="text-default">
            <el-dropdown style="color: #60AB42;" @command="handleAreaSelectCommand">
              <span class="el-dropdown-link location-select">
                <i class="el-icon-location"></i>
                <label v-if="!selectedArea">{{$t('home.select')}}</label>
                <label v-if="selectedArea">{{selectedArea.englishName}}</label>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in areas" :key="index" :command="index">{{item.englishName}}</el-dropdown-item>
                
                
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="logo">
            <img src="../../assets/logo.png">
          </div>
          <div style="flex:14 ;"></div>
          
          <el-tabs class="tabs" v-model="tabActiveName" @tab-click="tabHandleClick">
            <el-tab-pane v-if="newsAuth" :label="$t('home.news')" name="news"></el-tab-pane>
            <el-tab-pane v-if="outboundAuth" :label="$t('home.outbound')" name="outbound"></el-tab-pane>
            <el-tab-pane v-if="inboundAuth" :label="$t('home.inbound')" name="inbound"></el-tab-pane>
            
            <el-tab-pane v-if="mybookingAuth" :label="$t('home.mybooking')" name="mybooking"></el-tab-pane>
            <el-tab-pane v-if="commissionAuth" :label="$t('home.commission')" name="commission"></el-tab-pane>
            <el-tab-pane  :label="$t('home.tourismtermsPage')" name="tourismterms-page"></el-tab-pane>
            <el-tab-pane v-if="contractUsAuth" :label="$t('home.contractus')" name="contractus"></el-tab-pane>
            <!--<el-tab-pane v-if="personalCenterAuth" label="Personal Center" name="personalcenter"></el-tab-pane>-->
          </el-tabs>
          
         
          <div style="flex:4;font-size: 0.9rem;font-weight: 400;display: flex;align-items: center;">
            <el-dropdown style="margin-right: 1rem;text-align: right;font-size: 1rem;flex: 2;" @command="handleLanguageSelectCommand">
              <span class="el-dropdown-link language-select">
              {{selectedLanguage}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="en">English</el-dropdown-item>
                <el-dropdown-item command="tw">Chinese</el-dropdown-item>             
              </el-dropdown-menu>
            </el-dropdown>
            <label style="flex: 1;" v-if="account.accountName">
              Hi {{account?.accountName}}
            </label>
            <el-button style="flex: 1;" v-if="account.accountName" type="text" @click="logout">{{$t('home.logout')}}</el-button>
            <el-button style="flex: 1;" v-if="!account.accountName" type="text" @click="logout">{{$t('home.login')}}</el-button>
          </div>
          
        </div>

        <div class="header-mobile">
          <div style="flex: 2;">
            <el-dropdown style="color: #60AB42;font-size: 1rem;" @command="handleMenusSelectCommand">
              <span class="el-dropdown-link location-select">
                <i class="el-icon-menu menu-btn"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="newsAuth" command="news">{{$t('home.news')}}</el-dropdown-item>
                <el-dropdown-item v-if="outboundAuth" command="outbound">{{$t('home.outbound')}}</el-dropdown-item>
                <el-dropdown-item v-if="inboundAuth" command="inbound">{{$t('home.inbound')}}</el-dropdown-item>
                <el-dropdown-item v-if="mybookingAuth" command="mybooking">{{$t('home.mybooking')}}</el-dropdown-item>
                <el-dropdown-item v-if="commissionAuth" command="commission">{{$t('home.commission')}}</el-dropdown-item> 
                <el-dropdown-item command="tourismterms-page">{{$t('home.tourismtermsPage')}}</el-dropdown-item>
                <el-dropdown-item v-if="contractUsAuth" command="contractus">{{$t('home.contractus')}}</el-dropdown-item>  
              </el-dropdown-menu>
            </el-dropdown> 
          </div>
          <div style="flex: 3;text-align: center;">
            <el-dropdown style="color: #60AB42;font-size: 1rem;" @command="handleAreaSelectCommand">
              <span class="el-dropdown-link location-select">
                <i class="el-icon-location"></i>
                <label v-if="!selectedArea">{{$t('home.select')}}</label>
                <label v-if="selectedArea">{{$i18n.locale=='en'?selectedArea.englishName:selectedArea.chineseName}}</label>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in areas" :key="index" :command="index">{{$i18n.locale=='en'?item.englishName:item.chineseName}}</el-dropdown-item>
                
                
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          
          <div style="flex: 2;display: flex;padding-right: 0.5rem;font-size: 0.8rem;align-items: center;">
            <label style="flex: 1; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" v-if="account.accountName">
              {{account?.accountName}}
            </label>
            <el-button style="flex: 1;" v-if="account.accountName" type="text" @click="logout">{{$t('home.logout')}}</el-button>
            <el-button style="flex: 1;" v-if="!account.accountName" type="text" @click="logout">{{$t('home.login')}}</el-button>
          </div>
        </div>
      </div>
     <div class="page-content" v-if="showContent">
        <latestNews :Area="selectedArea" v-if="tabActiveName=='news'"></latestNews>
        <InboundTour :Area="selectedArea" @onBookSuccess="onBookingSuccess" v-if="tabActiveName=='inbound'"></InboundTour>
        <outboundTour :Area="selectedArea" v-if="tabActiveName=='outbound'"></outboundTour>
        <myBooking v-if="tabActiveName=='mybooking'"></myBooking>
        <commission :Area="null" v-if="tabActiveName=='commission'"></commission>
        <TourismTerms v-if="tabActiveName=='tourismterms-page'"></TourismTerms>
        <contractUs v-if="tabActiveName=='contractus'"></contractUs>
        <personalCenter v-if="tabActiveName=='personalcenter'"></personalCenter>
     </div>
     

      <div class="footer">

      </div>
     
    </div>
    
  </template>
  
  <script>
  import Vue from 'vue';
  import InboundTour from './components/inbound-tour.vue';
  import latestNews from './components/latest-news.vue';
  import outboundTour from './components/outbound-tour.vue';
  import myBooking from './components/my-booking.vue';
  import contractUs from './components/contract-us.vue';
  import personalCenter from './components/personal-center.vue';
  import commission from './components/commission.vue';
  import TourismTerms from './components/tourism-terms.vue';
  export default {
    components:{InboundTour,latestNews,outboundTour,myBooking,contractUs,personalCenter,commission,TourismTerms},
    name: 'home-page',
    data(){
      return{
        selectedLanguage:"English",
        tabActiveName:"news",
        account:{accountName:""},
        selectedArea:null,
        areas:[],
        showContent:false,
        newsAuth:true,
        outboundAuth:true,
        inboundAuth:true,
        mybookingAuth:true,
        commissionAuth:true,
        personalCenterAuth:true,
        contractUsAuth:true,

        selectedDistrict:null
      }
    },
    mounted(){
      this.getAccount();
      
    },
    methods:{
      handleLanguageSelectCommand(e){
       
        switch(e){
          case "en":
            this.selectedLanguage="English";
            break;
          case "tw":
            this.selectedLanguage="Chinese";
            break
        }
        this.$i18n.locale = e;
      },
      handleMenusSelectCommand(e){
        this.tabActiveName = e;
      },
      tabHandleClick(tab) {
        console.log(tab.name);
      },
      onBookingSuccess(){
        this.tabActiveName = "mybooking";
      },
      initData(){
        /*
        if(this.account.isAdmin){
          this.commissionAuth=false;
        }
        */
        this.getAreas();
        this.showContent = true;
      },
      getAccount(){
        let that = this;
        if(!this.$account){
          let token = this.$cookies.get("token");
          if(token){
            this.$httpservice.post('api/account/CurrentAccount',{token:token}).then(result => {
              if(result.status==200){
                  if(result.body.code=='200'){
                    Vue.prototype.$account = result.body.data;
                    that.account = result.body.data;
                    that.initData();
                  }else{
                    that.$cookies.remove("token");
                    Vue.prototype.$account=null;
                    that.$router.push("/login");
                  }
              }
            });
          }else if(!this.$district){
            this.$router.push("/selectaccountarea");
          }else{
            this.newsAuth = false;
            this.mybookingAuth = false;
            this.commissionAuth = false;
            this.personalCenterAuth = false;
            this.contractUsAuth = false;
            this.tabActiveName = "outbound";
            that.initData();
          }
        }else{
          that.account = this.$account;
          that.initData();
        }
      },
      getAreas(){
        let that = this;
        this.$httpservice.get('api/common/GetArea',).then(result => {
          if(result.status==200){
            if(result.body.code=='200'){
              that.areas = result.body.data;
            }else{
              this.$message({
                      message: '获取地区信息失败',
                      type: 'warning'
              });
            }
          }
        });
      },
      
      handleAreaSelectCommand(command){
        console.log(command);
        this.selectedArea = this.areas[command];
      },
      logout(){
        this.$cookies.remove("token");
        Vue.prototype.$account=null;
        this.$router.push("/login")
      }
    }
   
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){
  .header{
    background-color: #f0f5eb;
    width: 100%;
    height: 5rem;
    position:relative;
    display: flex;
    align-items: center;
    .logo{
        position: absolute;
        left: 13rem;
        img{
            width: 3rem;
        }
    }
    .tabs{
      position: absolute;
      bottom: -1rem;
      left: 20%;
    }
    .location-select{
      font-size: 1.2rem;
      margin-left: 2.5rem;

    }

    .language-select{
      font-size: 0.9rem;

    }

    .text-default{
      flex: 4;
      color: #60AB42;
    }
  }

  .header-mobile{
    display: none;
  }

  .page-content{
    margin-top: 5rem;
  }
}

@media screen and (max-width:1000px){
  .header{
    display: none;
  }
  .header-mobile{
    background-color: #f0f5eb;
    width: 100%;
    height: 3rem;
    position:relative;
    display: flex;
    align-items: center;
    .menu-btn{
      margin-left: 1rem;
      font-size: 1.4rem;
      color: #60AB42;
    }
  }

  .page-content{
    margin-top: 3rem;
  }
}

@media screen and (max-aspect-ratio:16/16) {
  .header{
    display: none;
  }
  .header-mobile{
    background-color: #f0f5eb;
    width: 100%;
    height: 3rem;
    position:relative;
    display: flex;
    align-items: center;
    .menu-btn{
      margin-left: 1rem;
      font-size: 1.4rem;
      color: #60AB42;
    }
  }

  .page-content{
    margin-top: 3rem;
  }
}


.carousel{
  img{
    width: 100%;
  }
}


</style>
  