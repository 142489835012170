<template>
    <div class="page">
      <div class="step-bar">
        <el-steps :active="stepActive" finish-status="success">
            <el-step :title="$t('bookingTour.selectTour')"></el-step>
            <el-step :title="$t('bookingTour.dateAndLanguage')"></el-step>
            <el-step :title="$t('bookingTour.passengerDetails')"></el-step>
            <el-step :title="$t('bookingTour.roomConfigure')"></el-step>
            <el-step :title="$t('bookingTour.serviceRequest')"></el-step>
            <el-step :title="$t('bookingSummary.bookingSummary')"></el-step>
        </el-steps>
      </div>
      <div>
        <div class="booking-content">
            <div class="booking-process" v-show="stepActive!=5">
                <!--Date & Language-->
                <el-card class="card" v-if="stepActive==1">
                    <div>
                        <div class="title">
                            <i class="el-icon-date"></i>
                            <label>{{$t('bookingTour.dateAndLanguage')}}</label>
                        </div>
                        <el-divider></el-divider>
                        <div class="form-container">
                            <div class="form-item">
                                <div class="key"><label>{{$t('bookingTour.selectATourDate')}}</label></div>
                                <div class="input-value"> 
                                    <div>
                                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="currentBookingObj.tourDate" class="controller" type="date" :placeholder="$t('bookingTour.selectDate')"></el-date-picker>
                                    </div>
                                    <div style="margin-top:1rem"><label>{{tour.frequency}}</label></div>
                                </div>
                                <!--
                                <div class="input-value">  
                                    <el-select v-model="currentBookingObj.tourDate" class="controller" placeholder="Please Select">
                                        <el-option 
                                            v-for="(item,index) in JSON.parse(tour.dateOfDeparturesStr)" 
                                            :key="index"
                                            :label="item.split('T')[0]"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                                -->
                            </div>
                            <div class="form-item">
                                <div class="key"><label>{{ $t('bookingTour.selectATourLanguage') }}</label></div>
                                <div class="input-value">  
                                    <el-select v-model="currentBookingObj.tourLanguage" class="controller" :placeholder="$t('bookingTour.pleaseSelect')">
                                        <el-option 
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="form-item">
                                <div class="key"><label>{{ $t('bookingTour.consultant') }}</label></div>
                                <div class="input-value"> 
                                    <el-input placeholder="Please Enter Consultant Name" v-model="currentBookingObj.consultant"></el-input> 
                                </div>
                            </div>
                            <div class="form-item">
                                <div class="key"><label>{{ $t('bookingTour.yourBookingReferNo') }}</label></div>
                                <div class="input-value">
                                    <el-input placeholder="Please Booking refer No" v-model="currentBookingObj.refNo"></el-input> 
                                </div>
                            </div>
                            <div style="text-align: right;margin-top: 2rem;">
                               
                                <el-button type="primary" @click="nextStep">{{ $t('bookingTour.next') }}</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

                <!--Passenger Details-->
                <el-card class="card" v-if="stepActive==2">
                    <div>
                        <div class="title">
                            <i class="el-icon-user-solid"></i>
                            <label>{{ $t('bookingTour.passenger') }}</label>
                        </div>
                        <el-divider></el-divider>
                        <div class="form-container">
                           
                            <div class="category-title"><label>{{ $t('bookingTour.passenger') }}</label></div>
                            <div  v-for="(item, index) in passengers" :key="index" style="display: flex;">
                                <div style="flex:1;font-weight: 500; font-size: 1.2rem; color:#167D5B ;line-height: 2.5rem;"><label>{{ index+1 }}.</label></div>
                                <div style="flex:15">
                                    <el-row :gutter="128">
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{ $t('bookingTour.lastName') }}</label></div>
                                                <div class="input-value"><el-input v-model="item.lastName"  :placeholder="$t('bookingTour.lastName')"></el-input></div>
                                            </div>
                                        
                                        </el-col>
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{ $t('bookingTour.firstName') }}</label></div>
                                                <div class="input-value"><el-input v-model="item.firstName"  :placeholder="$t('bookingTour.firstName')"></el-input></div>
                                            </div>
                                        </el-col>
                                    </el-row>

                                    <el-row :gutter="128">
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{ $t('bookingTour.birthday') }}</label></div>
                                                <div class="input-value"><el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="controller" v-model="item.birthday" type="date" :placeholder="$t('bookingTour.selectDate')"></el-date-picker></div>
                                            </div>
                                        
                                        </el-col>
                                        
                                    </el-row>

                                   
                                    <el-row :gutter="128">
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{$t('bookingTour.gender')}}</label></div>
                                                <div class="input-value">
                                                    <el-select v-model="item.gender" class="controller" :placeholder="$t('bookingTour.pleaseSelect')">
                                                        <el-option                                      
                                                            key="1"
                                                            label="Male"
                                                            value="Male">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="0"
                                                            label="Female"
                                                            value="Female">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>                                       
                                        </el-col>    
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{$t('bookingTour.nationality')}}</label></div>
                                                <div class="input-value">
                                                    <el-select v-model="item.nationality" class="controller" :placeholder="$t('bookingTour.pleaseSelect')">
                                                        <el-option                                      
                                                            key="1"
                                                            label="Chinese"
                                                            value="Chinese">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="2"
                                                            label="HongKong and Macao"
                                                            value="HongKong and Macao">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="3"
                                                            label="Taiwanese"
                                                            value="Taiwanese">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="4"
                                                            label="American"
                                                            value="American">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="5"
                                                            label="Canadian"
                                                            value="Canadian">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="6"
                                                            label="Australia"
                                                            value="Australia">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="7"
                                                            label="New Zealand"
                                                            value="New Zealand">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="8"
                                                            label="新加坡Singapore"
                                                            value="新加坡Singapore">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="9"
                                                            label="马来西亚Malaysia"
                                                            value="马来西亚Malaysia">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="10"
                                                            label="越南Vietnam"
                                                            value="越南Vietnam">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="11"
                                                            label="柬埔寨Cambodia"
                                                            value="柬埔寨Cambodia">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="12"
                                                            label="菲律宾Philippines"
                                                            value="菲律宾Philippines">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="13"
                                                            label="印尼Indonesia"
                                                            value="印尼Indonesia">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="13"
                                                            label="欧洲Europe"
                                                            value="欧洲Europe">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>            
                                        </el-col> 
                                                               
                                    </el-row>
                                    <el-row :gutter="128" v-if="!isInbound">
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{ $t('bookingTour.passportNo') }}</label></div>
                                                <div class="input-value"><el-input v-model="item.passportNo"  :placeholder="$t('bookingTour.pleaseInput')"></el-input></div>
                                            </div>
                                        </el-col>  
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{ $t('bookingTour.PR') }}</label></div>
                                                <div class="input-value">
                                                    <el-select v-model="item.hasPR" class="controller" :placeholder="$t('bookingTour.pleaseSelect')">
                                                        <el-option                                      
                                                            key="1"
                                                            label="Yes"
                                                            :value="true">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="2"
                                                            label="No"
                                                            :value="false">
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="128" v-if="!isInbound">
                                        
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{ $t('bookingTour.speaking') }}</label></div>
                                                <div class="input-value">
                                                    <el-select v-model="item.speaking" class="controller" placeholder="Please Select">
                                                        <el-option                                      
                                                            key="1"
                                                            label="Fluent-Chinese"
                                                            value="Fluent-Chinese">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="2"
                                                            label="None-Fluent-Chinese"
                                                            value="None-Fluent-Chinese">
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="128">
                                        <el-col :span="24"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{$t('bookingTour.passengerType')}}</label></div>
                                                <div class="input-value">
                                                    <el-select v-model="item.passengerType" class="controller" :placeholder="$t('bookingTour.pleaseSelect')">
                                                        <!--
                                                        <el-option                                      
                                                            key="1"
                                                            label="Infant"
                                                            value="Infant">
                                                        </el-option>
                                                        -->
                                                        <el-option                                      
                                                            key="2"
                                                            label="Child"
                                                            value="Child">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="3"
                                                            label="Adult"
                                                            value="Adult">
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </div>
                                            </div>            
                                        </el-col> 
                                        <el-col :span="24" v-if="item.passengerType&&item.passengerType!='Adult'"> 
                                            <div class="form-item">
                                                <div class="key"><label>{{ $t('bookingTour.needbed') }}</label></div>
                                                <div class="input-value">
                                                    <el-select v-model="item.withBed" class="controller" :placeholder="$t('bookingTour.pleaseSelect')">
                                                        <el-option                                      
                                                            key="1"
                                                            label="Yes"
                                                            :value="1">
                                                        </el-option>
                                                        <el-option                                      
                                                            key="2"
                                                            label="No"
                                                            :value="2">
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </div>
                                            </div>
                                        </el-col>
                                        
                                    </el-row>

                                    <el-divider></el-divider>
                                </div>
                                
                                
                            </div>
                            
                            <div>
                                <el-button type="primary" icon="el-icon-plus" circle @click="addPassenger"></el-button>
                                <el-button type="primary" icon="el-icon-minus" circle @click="removePassenger"></el-button>
                            </div>
                            <div style="text-align: right;margin-top: 2rem;">
                                <el-button type="primary" @click="preStep">{{ $t('bookingTour.back') }}</el-button>
                                <el-button type="primary" @click="nextStep">{{ $t('bookingTour.next') }}</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

                <!--Room Configure-->
                <el-card class="card" v-if="stepActive==3">
                    <div>
                        <div class="title">
                            <i class="el-icon-office-building"></i>
                            <label>{{ $t('bookingTour.roomConfigure') }}</label>
                        </div>
                        <el-divider></el-divider>
                        <div class="form-container">
                            <div class="form-item">
                                <div class="key"><label>{{ $t('bookingTour.singleRoom') }}</label></div>
                                <div class="input-value"><el-input-number v-model="currentBookingObj.singleRoom" class="controller" :min="0" :placeholder="$t('bookingTour.numberOfRooms')"></el-input-number></div>
                            </div>
                            <div class="form-item">
                                <div class="key"><label>{{ $t('bookingTour.twinRoom') }}</label></div>
                                <div class="input-value">  
                                    <el-input-number :min="0" v-model="currentBookingObj.twinRoom"  class="controller" :placeholder="$t('bookingTour.numberOfRooms')"></el-input-number>
                                </div>
                            </div>
                            <!--
                            <div class="form-item">
                                <div class="key"><label>Double Room</label></div>
                                <div class="input-value"> 
                                    <el-input-number :min="0" v-model="currentBookingObj.doubleRoom"  class="controller" placeholder="Number of rooms"></el-input-number>
                                </div>
                            </div>
                            -->
                            <div class="form-item">
                                <div class="key"><label>{{ $t('bookingTour.twinRoomExtraBed') }}</label></div>
                                <div class="input-value">
                                    <el-input-number :min="0" v-model="currentBookingObj.tripleRoom"  class="controller" placeholder="Number of rooms"></el-input-number>
                                </div>
                            </div>
                            <div style="text-align: right;margin-top: 2rem;">
                                <el-button type="primary" @click="preStep">{{ $t('bookingTour.back') }}</el-button>
                                <el-button type="primary" @click="nextStep">{{ $t('bookingTour.next') }}</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

                <!--Service Request-->
                <el-card class="card" v-if="stepActive==4">
                    <div>
                        <div class="title">
                            <i class="el-icon-s-promotion"></i>
                            <label>{{ $t('bookingTour.serviceRequest') }}</label>
                        </div>
                        <el-divider></el-divider>
                        <div class="form-container">

                            <!--Transfer Services-->
                            <div>
                                <div class="category-title"><label>{{ $t('bookingTour.transferServices') }}</label></div>
                            
                                <!--Arrival Details-->
                                <div>
                                    <div class="sub-category">
                                        <div class="sub-category-title">
                                            <label>{{ $t('bookingTour.arrivalDetails') }}</label>
                                        </div>
                                        <div class="check">
                                           
                                        </div>                                
                                    </div>
                                    <el-divider></el-divider>
                                    <div v-for="(item,index) in arrivalDetails" :key="index">
                                        <el-row :gutter="64" class="row">
                                            <el-col :span="12" class="row-item">
                                                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="item.time" type="datetime" :placeholder="$t('bookingTour.selectDate')"></el-date-picker>
                                            </el-col>
                                            <el-col :span="12" class="row-item">
                                                <el-input v-model="item.flightNo" :placeholder="$t('bookingTour.flightNo')"></el-input>
                                            </el-col>
                                            <el-col :span="12" class="row-item">
                                                <el-select filterable v-model="item.adelaideAirport" :placeholder="$t('bookingTour.pleaseSelect')">
                                                    <el-option
                                                        v-for="airPort in airPorts"
                                                        :key="airPort.value"
                                                        :label="airPort.englishName"
                                                        :value="airPort.value">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col :span="12" class="row-item">
                                                <el-select  v-model="item.passengerName" :placeholder="$t('bookingTour.noneSelected')">
                                                    <el-option
                                                        v-for="passenger in passengers"
                                                        :key="passenger.firstName+' '+passenger.lastName"
                                                        :label="passenger.firstName+' '+passenger.lastName"
                                                        :value="passenger.firstName+' '+passenger.lastName">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <el-row style="margin-top: 1rem;">
                                        <el-col :span="12">
                                            <el-button type="primary" size="small" icon="el-icon-plus" @click="transferServiceItemAdd(1)"></el-button>
                                            <el-button type="primary" size="small" icon="el-icon-minus" @click="transferServiceItemReduce(1)"></el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                            
                                <!--Departure Details-->
                                <div>
                                    <div class="sub-category">
                                        <div class="sub-category-title">
                                            <label>{{$t('bookingTour.departureDetails')}}</label>
                                        </div>
                                        <div class="check">
                                            
                                        </div>                                
                                    </div>
                                    <el-divider></el-divider>
                                    <div v-for="(item,index) in departureDetails" :key="index">
                                        <el-row :gutter="48" class="row">
                                            <el-col :span="12" class="row-item">
                                                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="item.time" type="datetime" :placeholder="$t('bookingTour.selectDate')"></el-date-picker>
                                            </el-col>
                                            <el-col :span="12" class="row-item">
                                                <el-input v-model="item.flightNo" :placeholder="$t('bookingTour.flightNo')"></el-input>
                                            </el-col>
                                            
                                            
                                        </el-row>
                                        <el-row :gutter="48" class="row">
                                            <el-col :span="12" class="row-item">
                                                <el-select filterable v-model="item.adelaideAirport" :placeholder="$t('bookingTour.pleaseSelect')">
                                                    <el-option
                                                        v-for="airPort in airPorts"
                                                        :key="airPort.value"
                                                        :label="airPort.englishName"
                                                        :value="airPort.value">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col :span="12" class="row-item">
                                                <el-select  v-model="item.passengerName" :placeholder="$t('bookingTour.noneSelected')">
                                                    <el-option
                                                        v-for="passenger in passengers"
                                                        :key="passenger.firstName+' '+passenger.lastName"
                                                        :label="passenger.firstName+' '+passenger.lastName"
                                                        :value="passenger.firstName+' '+passenger.lastName">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                           
                                        </el-row>
                                    </div>
                                    <el-row style="margin-top: 1rem;">
                                        <el-col :span="12">
                                            <el-button type="primary" size="small" icon="el-icon-plus" @click="transferServiceItemAdd(2)"></el-button>
                                            <el-button type="primary" size="small" icon="el-icon-minus" @click="transferServiceItemReduce(2)"></el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>

                             <!--Pre/Post Tour Accommodation-->
                             <div v-if="!isInbound">
                                <div class="category-title"><label>{{ $t('bookingTour.prePostTourAccommodation') }}</label></div>
                            
                                <!--Pre Tour Accommodation-->
                                <div>
                                    <div class="sub-category">
                                        <div class="sub-category-title">
                                            <label>{{ $t('bookingTour.preTourAccommodation') }}</label>
                                        </div>
                                        <div class="check">
                                            
                                        </div>                                
                                    </div>
                                    <el-divider></el-divider>
                                    <div  v-for="(item,index) in preTourAccommodations" :key="index">
                                        <el-row :gutter="48" class="row">
                                            <el-col :span="12">
                                                <el-select v-model="item.nights" :placeholder="$t('bookingTour.night')">
                                                    <el-option
                                                        :key="1"
                                                        :label="$t('bookingTour.oneNight')"
                                                        :value="1">
                                                    </el-option>
                                                    <el-option
                                                        :key="2"
                                                        :label="$t('bookingTour.twoNight')"
                                                        :value="2">
                                                    </el-option>
                                                    <el-option
                                                        :key="3"
                                                        :label="$t('bookingTour.threeNight')"
                                                        :value="3">
                                                    </el-option>
                                                    <el-option
                                                        :key="4"
                                                        :label="$t('bookingTour.fourNight')"
                                                        :value="4">
                                                    </el-option>
                                                    <el-option
                                                        :key="5"
                                                        :label="$t('bookingTour.fiveNight')"
                                                        :value="5">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-select v-model="item.roomType" :placeholder="$t('bookingTour.roomType')">
                                                    <el-option
                                                        :key="1"
                                                        :label="$t('bookingTour.single')"
                                                        value="Single">
                                                    </el-option>
                                                    <el-option
                                                        :key="2"
                                                        :label="$t('bookingTour.double')"
                                                        value="Double">
                                                    </el-option>
                                                    <el-option
                                                        :key="3"
                                                        :label="$t('bookingTour.twin')"
                                                        value="Twin">
                                                    </el-option>
                                                    <el-option
                                                        :key="4"
                                                        :label="$t('bookingTour.triple')"
                                                        value="Triple">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            
                                        </el-row>
                                        <el-row :gutter="48" class="row">
                                            <el-col :span="12">
                                                <el-select v-model="item.passengerName" :placeholder="$t('bookingTour.pleaseSelect')">
                                                    <el-option
                                                        v-for="passenger in passengers"
                                                        :key="passenger.firstName+' '+passenger.lastName"
                                                        :label="passenger.firstName+' '+passenger.lastName"
                                                        :value="passenger.firstName+' '+passenger.lastName">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                        </el-row>
                                        
                                    </div>
                                    <el-row :gutter="48" class="row">
                                        <el-col :span="12">
                                            <el-button type="primary" size="small" icon="el-icon-plus" @click="transferServiceItemAdd(3)"></el-button>
                                            <el-button type="primary" size="small" icon="el-icon-minus" @click="transferServiceItemReduce(3)"></el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                            
                                <!--Post Tour Accommodation-->
                                <div>
                                    <div class="sub-category">
                                        <div class="sub-category-title">
                                            <label>{{$t('bookingTour.postTourAccommodation')}}</label>
                                        </div>
                                        <div class="check">
                                            
                                        </div>                                
                                    </div>
                                    <el-divider></el-divider>
                                    <div  v-for="(item,index) in postTourAccommodations" :key="index">
                                        <el-row :gutter="48" class="row">
                                            <el-col :span="12">
                                                <el-select v-model="item.nights" placeholder="-- Night/s --">
                                                    <el-option
                                                        :key="1"
                                                        :label="$t('bookingTour.oneNight')"
                                                        :value="1">
                                                    </el-option>
                                                    <el-option
                                                        :key="2"
                                                        :label="$t('bookingTour.twoNight')"
                                                        :value="2">
                                                    </el-option>
                                                    <el-option
                                                        :key="3"
                                                        :label="$t('bookingTour.threeNight')"
                                                        :value="3">
                                                    </el-option>
                                                    <el-option
                                                        :key="4"
                                                        :label="$t('bookingTour.fourNight')"
                                                        :value="4">
                                                    </el-option>
                                                    <el-option
                                                        :key="5"
                                                        :label="$t('bookingTour.fiveNight')"
                                                        :value="5">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-select v-model="item.roomType" placeholder="-- Room Type --">
                                                    <el-option
                                                        :key="1"
                                                        :label="$t('bookingTour.single')"
                                                        value="Single">
                                                    </el-option>
                                                    <el-option
                                                        :key="2"
                                                        :label="$t('bookingTour.double')"
                                                        value="Double">
                                                    </el-option>
                                                    <el-option
                                                        :key="3"
                                                        :label="$t('bookingTour.twin')"
                                                        value="Twin">
                                                    </el-option>
                                                    <el-option
                                                        :key="4"
                                                        :label="$t('bookingTour.triple')"
                                                        value="Triple">
                                                    </el-option>
                                                   
                                                </el-select>
                                            </el-col>
                                            
                                        </el-row> 
                                        <el-row :gutter="48" class="row">
                                            <el-col :span="12">
                                                <el-select v-model="item.passengerName" placeholder="Please Select">
                                                    <el-option
                                                        v-for="passenger in passengers"
                                                        :key="passenger.firstName+' '+passenger.lastName"
                                                        :label="passenger.firstName+' '+passenger.lastName"
                                                        :value="passenger.firstName+' '+passenger.lastName">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                        </el-row>                                     
                                    </div>
                                    <el-row :gutter="48" class="row">
                                        <el-col :span="12">
                                            <el-button type="primary" size="small" icon="el-icon-plus" @click="transferServiceItemAdd(4)"></el-button>
                                            <el-button type="primary" size="small" icon="el-icon-minus" @click="transferServiceItemReduce(4)"></el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            
                            <!--Special Request-->
                            <div>
                                <div class="category-title"><label>{{ $t('bookingTour.serviceRequest') }}</label></div>
                                <div>
                                    <el-input
                                        v-model="currentBookingObj.specialRequest"
                                        type="textarea"
                                        :rows="5"
                                        :placeholder="$t('bookingTour.pleaseTypeYourSpecialRequest')">
                                       
                                    </el-input>
                                </div>
                            </div>

                             <!--ls Compulsory and tipping prepaid?-->
                             <div>
                                <div class="category-title"><label>{{$t('bookingTour.isCompulsoryProgramPrepaid')}}</label></div>
                                <div>
                                    <el-select v-model="currentBookingObj.compulsoryPrepaid" placeholder="-- Prepaid --">
                                        <el-option                                          
                                            key="Yes"
                                            label="Yes"
                                            :value="true">
                                        </el-option>
                                        <el-option                                          
                                            key="No"
                                            label="No"
                                            :value="false">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <div>
                                <div class="category-title"><label>{{ $t('bookingTour.isTippingprepaid') }}</label></div>
                                <div>
                                    <el-select v-model="currentBookingObj.tippingPrepaid" placeholder="-- Prepaid --">
                                        <el-option                                          
                                            key="Yes"
                                            label="Yes"
                                            :value="true">
                                        </el-option>
                                        <el-option                                          
                                            key="No"
                                            label="No"
                                            :value="false">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <div style="text-align: right;margin-top: 2rem;">
                                <el-button type="primary" @click="preStep">{{$t('bookingTour.back')}}</el-button>
                                <el-button type="primary" @click="nextStep">{{$t('bookingTour.next')}}</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="booking-summary-container" v-show="stepActive!=5">
                <el-card class="card">
                    <div class="summary">
                        <div class="booking-summary">{{$t('bookingSummary.bookingSummary')}}</div>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourCode')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{tour.tourCode}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourName')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{tour.tourName}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.travelDate')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{tour.duration}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourDate')}}</label></el-col>
                            <el-col v-if="currentBookingObj.tourDate" :span="14"><label class="value">{{currentBookingObj.tourDate}}</label></el-col>
                            <el-col v-else :span="14"><label class="value">N/A</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourLanguage')}}</label></el-col>
                            <el-col v-if="currentBookingObj.tourLanguage" :span="14"><label class="value">{{currentBookingObj.tourLanguage}}</label></el-col>
                            <el-col v-else :span="14"><label class="value">N/A</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.consultant')}}</label></el-col>
                            <el-col v-if="currentBookingObj.consultant" :span="10"><label class="key">{{currentBookingObj.consultant}}</label></el-col>
                            <el-col v-else :span="14"><label class="value">N/A</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.bookingRefNo')}}</label></el-col>
                            <el-col v-if="currentBookingObj.refNo" :span="14"><label class="value">{{currentBookingObj.refNo}}</label></el-col>
                            <el-col v-else :span="14"><label class="value">N/A</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.passengers')}}</label></el-col>
                            <el-col :span="14"><div v-for="(item,index) in passengers" :key="index"><label class="value">{{ item.firstName+" "+item.lastName }}</label></div></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.numberOfPassengers')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{ passengers.length }}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.numberOfRooms')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{(currentBookingObj.singleRoom+currentBookingObj.twinRoom+currentBookingObj.doubleRoom+currentBookingObj.tripleRoom)}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                       
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.pickupPointDetails')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.pickupPoint}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.arrivalDetail')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in arrivalDetails" :key="index">
                                    <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.departureDetails')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in departureDetails" :key="index">
                                    <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.compulsoryPrepaid')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.compulsoryPrepaid==true?"Yes":"No"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tippingPrepaid')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.tippingPrepaid==true?"Yes":"No"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row" v-if="!isInbound">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.preHotel')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in preTourAccommodations" :key="index">
                                    <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row"  v-if="!isInbound">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.postHotel')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in postTourAccommodations" :key="index">
                                    <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.bookingID')}}</label></el-col>
                            <el-col :span="14"><label class="value">N/A</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                       
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.specialRequest')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.specialRequest?currentBookingObj.specialRequest:"N/A"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>

                    </div>
                </el-card>
            </div>

             <!--Booking Summary-->
             <el-card class="summary-card" v-if="stepActive==5">
                <div style="display: flex;justify-content: center;">
                    <div class="summary-step">
                        <div class="booking-summary">{{$t('bookingSummary.bookingSummary')}}</div>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourCode')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{tour.tourCode}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourName')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{tour.tourName}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.travelDate')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{tour.duration}} Day(s)</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourDate')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.tourDate?currentBookingObj.tourDate:"N/A"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tourLanguage')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.tourLanguage?currentBookingObj.tourLanguage:"N/A"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.consultant')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.consultant?currentBookingObj.consultant:"N/A"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.bookingRefNo')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.refNo?currentBookingObj.refNo:"N/A"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.passengers')}}</label></el-col>
                            <el-col :span="14"><div v-for="(item,index) in passengers" :key="index"><label class="value">{{ item.firstName+" "+item.lastName }}</label></div></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.numberOfPassengers')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{ passengers.length }}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.numberOfRooms')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{(currentBookingObj.singleRoom+currentBookingObj.twinRoom+currentBookingObj.doubleRoom+currentBookingObj.tripleRoom)}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.pickupPointDetails')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.pickupPoint?currentBookingObj.pickupPoint:"N/A"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.arrivalDetail')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in arrivalDetails" :key="index">
                                    <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.departureDetails')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in departureDetails" :key="index">
                                    <label class="value">{{item.time+" "+item.adelaideAirport+" "+ item.flightNo+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.compulsoryPrepaid')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.compulsoryPrepaid==true?"Yes":"No"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.tippingPrepaid')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.tippingPrepaid==true?"Yes":"No"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>


                        <el-row :gutter="48" class="row" v-if="!isInbound">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.preHotel')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in preTourAccommodations" :key="index">
                                    <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider v-if="!isInbound"></el-divider>

                        <el-row :gutter="48" class="row"  v-if="!isInbound">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.postHotel')}}</label></el-col>
                            <el-col :span="14">
                                <div v-for="(item,index) in postTourAccommodations" :key="index">
                                    <label class="value">{{item.nights+"Nights/"+item.roomType+"/"+" ("+item.passengerName+")"}}</label>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider v-if="!isInbound"></el-divider>

                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.bookingID')}}</label></el-col>
                            <el-col :span="14"><label class="value">N/A</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row :gutter="48" class="row">
                            <el-col :span="10"><label class="key">{{$t('bookingSummary.specialRequest')}}</label></el-col>
                            <el-col :span="14"><label class="value">{{currentBookingObj.specialRequest?currentBookingObj.specialRequest:"N/A"}}</label></el-col>
                        </el-row>
                        <el-divider></el-divider>
                    </div>
                    
                </div>
                <div style="text-align: right;margin-top: 2rem;">
                    <el-button type="primary" @click="preStep">{{$t('bookingTour.back')}}</el-button>
                    <el-button type="primary" @click="submitClick" :loading="submitting">{{$t('bookingTour.submit')}}</el-button>
                </div>   
            </el-card>
        </div>
      </div>
    </div>
    
</template>
  
<script>
export default {
    props:{
        tour:null,
        isInbound:null
    },  
  name: 'booking-tour',
  data(){
    return {
        submitting:false,
        stepActive:1,
        options: [{
            value: 'Chinese',
            label: 'Chinese'
            }, {
            value: 'English',
            label: 'English'
            }
        ],
        passengers:[],
        currentBookingObj:{
            tripId: 0,
            refNo: "",
            tourLanguage: "",
            consultant: "",
            tourDate: null,
            singleRoom: 0,
            twinRoom: 0,
            doubleRoom: 0,
            tripleRoom: 0,
            specialRequest: "",
            compulsoryPrepaid: false,
            tippingPrepaid: false,
            bookingStatus: 0,
            adultNum:0,
            childNum:0,
            infantwithSeatNum:0,
            infantwithoutSeatNum:0,
            childWithBedNum:0,
            childWithoutBedNum:0,
            pickupPoint:""
        },
        arrivalDetails:[],
        departureDetails:[],
        preTourAccommodations:[],
        postTourAccommodations:[],
        airPorts:[],
        


    }
  },
  mounted(){
    this.getAirPorts();
  },
  methods:{
    preStep(){
        if( this.stepActive>1){
            this.stepActive--;
        }
    },
    nextStep(){
        let canNext = true;
        switch(this.stepActive){
            case 1:
                if(this.currentBookingObj.tourDate&&this.currentBookingObj.tourLanguage&&this.currentBookingObj.consultant){
                    this.stepActive++;
                }else{
                    this.$message({
                      message: 'Please fill out all the items',
                      type: 'warning'
                    });
                }
                break;
            case 2:           
                this.passengers.forEach(passenger => {
                    if(this.isInbound){
                        if(!passenger.lastName||!passenger.firstName||!passenger.gender||!passenger.nationality||!passenger.passengerType){
                            canNext=false;
                        }
                        if(passenger.passengerType&&passenger.passengerType!="Adult"&&passenger.withBed==undefined){
                            canNext=false;
                        }
                    }else{
                        if(!passenger.lastName||!passenger.firstName||!passenger.gender||!passenger.nationality||passenger.hasPR==undefined||!passenger.speaking||!passenger.passengerType){
                            canNext=false;
                        } 
                        if(passenger.passengerType&&passenger.passengerType!="Adult"&&passenger.withBed==undefined){
                            canNext=false;
                        }
                    }
                });
                if(!canNext){
                    this.$message({
                      message: 'Please fill out all the required items',
                      type: 'warning'
                    });
                }else{
                    this.stepActive++;
                }
                break;
            case 3:
                this.stepActive++;
                break;
            case 4:
                this.arrivalDetails.forEach(arrivalDetail => {
                    

                    if(!arrivalDetail.time||!arrivalDetail.flightNo||!arrivalDetail.adelaideAirport||!arrivalDetail.passengerName){
                        canNext=false;
                    }
                });
                this.departureDetails.forEach(departureDetail => {
                    if(!departureDetail.time||!departureDetail.flightNo||!departureDetail.adelaideAirport||!departureDetail.passengerName){
                        canNext=false;
                    }
                });
                this.preTourAccommodations.forEach(preTourAccommodation => {
                    if(!preTourAccommodation.nights||!preTourAccommodation.roomType||!preTourAccommodation.passengerName){
                        canNext=false;
                    }
                });
                this.postTourAccommodations.forEach(postTourAccommodation => {
                    if(!postTourAccommodation.nights||!postTourAccommodation.roomType||!postTourAccommodation.passengerName){
                        canNext=false;
                    }
                });
                console.log("arrivalDetails",this.arrivalDetails);
                console.log("departureDetails", this.departureDetails);
                console.log("preTourAccommodations",this.preTourAccommodations);
                console.log("postTourAccommodations",this.postTourAccommodations);

                if(!canNext){
                    this.$message({
                      message: 'Please fill out all the required items',
                      type: 'warning'
                    });
                }else{
                    this.stepActive++;
                }
                break;
        }
        
    },
    addPassenger(){
        let object = new Object();
        this.passengers.push(object)
    },
    removePassenger(){
        this.passengers.splice(this.passengers.length-1,1);
    },
    transferServiceItemAdd(flag){
        let object = new Object();
        switch(flag){
            case 1:
                this.arrivalDetails.push(object);
            break;
            case 2:
                this.departureDetails.push(object);
            break;
            case 3:
                this.preTourAccommodations.push(object);
            break;
            case 4:
                this.postTourAccommodations.push(object);
            break;
        }
       
    },
    transferServiceItemReduce(flag){
        switch(flag){
            case 1:
                this.arrivalDetails.splice(this.arrivalDetails.length-1,1);
            break;
            case 2:
                this.departureDetails.splice(this.departureDetails.length-1,1);
            break;
            case 3:
                this.preTourAccommodations.splice(this.preTourAccommodations.length-1,1);
            break;
            case 4:
                this.postTourAccommodations.splice(this.postTourAccommodations.length-1,1);
            break;
        }
    },
    getAirPorts(){
        let that = this;
        let apiPoint = 'api/common/GetInBoundAirports';
        if(!this.isInbound){
            apiPoint = 'api/common/GetOutBoundAirports';
        }
        this.$httpservice.get(apiPoint).then(result => {
          if(result.status==200){
            if(result.body.code=='200'){
              that.airPorts = result.body.data;
            }else{
              that.$message({
                      message: 'Get Airports Failed',
                      type: 'warning'
              });
            }
          }
        });
    },
    submitClick(){
        let that = this;
        this.submitting=true;
        this.passengers.forEach(passenger => {
            passenger.firstLastName = passenger.firstName+" "+passenger.lastName
        });
        this.arrivalDetails.forEach(arrivalDetail => {
            arrivalDetail.passenger = this.passengers.find(x=>x.firstLastName==arrivalDetail.passengerName);
        });
        this.departureDetails.forEach(departureDetail => {
            departureDetail.passenger = this.passengers.find(x=>x.firstLastName==departureDetail.passengerName);
        });
        this.preTourAccommodations.forEach(preTourAccommodation => {
            preTourAccommodation.passenger = this.passengers.find(x=>x.firstLastName==preTourAccommodation.passengerName);
        });
        this.postTourAccommodations.forEach(postTourAccommodation => {
            postTourAccommodation.passenger = this.passengers.find(x=>x.firstLastName==postTourAccommodation.passengerName);
        });
        this.currentBookingObj.tripId = this.tour.id;
        this.currentBookingObj.passengers = this.passengers;
        this.currentBookingObj.arrivalDetails = this.arrivalDetails;
        this.currentBookingObj.departureDetails = this.departureDetails;
        this.currentBookingObj.preTourAccommodations = this.preTourAccommodations;
        this.currentBookingObj.postTourAccommodations = this.postTourAccommodations;

        this.$httpservice.post("api/Booking/Add",this.currentBookingObj).then(result => {
            that.submitting=false;
            if(result.status==200){
                if(result.body.code=='200'){
                    that.$message({
                        message: 'Book Successfully',
                        type: 'success'
                    });
                    that.$emit('onBookSuccess', result.body)
                }else{
                    that.$message({
                        message: 'Book Failed',
                        type: 'warning'
                });
                }
            }
        });

        
    }
  }
   
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){
    .booking-content{
        display:flex;
        .booking-process{
            flex:14;margin-right: 1rem;
        }

        .booking-summary-container{
            flex:10
        }
    }

    .page{
        padding-left: 10vw;
        padding-right: 10vw;
        padding-top: 3rem;
    }
}

@media screen and (max-width:1000px) {
    .booking-content{
        display:flex;
        .booking-process{
            margin-right: 1rem;
            width: 100%;
        }

        .booking-summary-container{
            display: none;
        }
    }

    .page{
        padding: 1rem;
        .step-bar{
            display: none;
        }
    }
}

@media screen and (max-aspect-ratio:16/16) {
    .booking-content{
        display:flex;
        .booking-process{
           margin-right: 1rem;
           width: 100%;
        }

        .booking-summary-container{
            display: none;
        }
    }

    .page{
        padding: 1rem;
        .step-bar{
            display: none;
        }
    }
}

.form-container{
    .row{
        margin-top: 1rem;
        .row-item{
            margin-top: 1rem;
        }
    }
}
.sub-category{
    margin-top: 2rem;
    display: flex;
    .sub-category-title{
        flex: 1;
        font-size: 1rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
    }
    .check{
        font-size: 1.12rem;
        flex:1;
        text-align: right;
    }
}
.category-title{
    margin-top: 2rem;margin-bottom: 1rem;color: #167D5B;font-size: 1.2rem;font-weight: 400;
}
.summary-step{
    width: 90%;
    .row{
        margin-top: 1.5rem;
    }
    .key{
        color:#444444;
        font-size: 1.12rem;
    }
    .value{
        color:#999999;
        font-size: 1.12rem;
    }
}
.booking-summary{
    background-color: #408F21;height: 3.125rem;color: white;display: flex;justify-content: center;align-items: center;
    
}
.summary{
    .row{
        margin-top: 1.5rem;
    }
    .key{
        color:#444444;
        font-size: 1.12rem;
    }
    .value{
        color:#999999;
        font-size: 1.12rem;
    }
}
.form-item{
    display:flex;
    align-items:center;
    margin-bottom: 1rem;
    .key{
        flex:2;
        margin-right: 1rem;
    }
    .input-value{
        flex:3;
        .controller{
            width: 100%;
        }
    }
}
.title{
    color:#167D5B;
    display: flex;
    align-items: center;
    i{
        font-size: 2rem;
        margin-right: 1rem;
    }
    label{
        font-size: 1.5rem;
    }
}
.card{
    margin-top: 2rem;
    width: 100%;
    background-color: #F5F7FB;
}

.summary-card{
    margin-top: 2rem;
    width: 100%;
    background-color: #F5F7FB;
}


</style>
  