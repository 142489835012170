<template>
    <div>
      home
      <el-divider class="hidden-sm-and-up">
        <el-row>
          <el-button>默认按钮</el-button>
          <el-button type="primary">主要按钮</el-button>
          <el-button type="success">成功按钮</el-button>
          <el-button type="info">信息按钮</el-button>
          <el-button type="warning">警告按钮</el-button>
          <el-button type="danger">危险按钮</el-button>
        </el-row>
      </el-divider>
      <div class="container">

      </div>
      <el-tabs>
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>
    </div>
    
</template>
  
<script>
export default {
  name: 'example-page'
   
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-aspect-ratio:12/16) {
  .container {
        width: 307px;
        height: 50px;
        background: black;
        margin-left: auto !important;
        margin-right: auto !important;
  }
}

//手机的样式 另一种写法
@media not screen and (min-aspect-ratio:12/16) {
  .container {
        width: 307px;
        height: 50px;
        background: red;
        margin-left: auto !important;
        margin-right: auto !important;
  }
}
/*
//手机的样式     // 如果宽高比最大是12:16的话，显示这个内容
@media screen and (max-aspect-ratio:12/16) {
  .container {
        width: 307px;
        height: 50px;
        background: red;
        margin-left: auto !important;
        margin-right: auto !important;
  }
}

@media screen and (aspect-ratio: 12/16) {
  .container {
        width: 307px;
        height: 50px;
        background: red;
        margin-left: auto !important;
        margin-right: auto !important;
  }
}
*/
</style>
  