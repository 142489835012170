import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import 'element-ui/lib/theme-chalk/display.css';
import '../theme/index.css';
import VueI18n from 'vue-i18n';
import twlang from './utilies/language/tw';
import enlang from './utilies/language/en';
import './style/style.scss';

import VueCookies from 'vue-cookies';



Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(VueCookies);

import service from './service/HttpService';
import VueResource from 'vue-resource';

Vue.use(VueResource);
Vue.prototype.$httpservice = service;

const i18n = new VueI18n({
  locale: 'en', // 默认语言
  messages: {
    'tw': twlang,
    'en': enlang
  }
});

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app');
